import {CollectionListInterface} from '@api/collection';
import {searchData} from '@api/common';
import {ItemInterface} from '@api/item';
import {UserInterface} from '@api/user';
import LoadingSpinner from '@components/common/loading-spinner';
import React, {useEffect, useState} from 'react';
import SuggestionItem from './suggestion_item';

export const SuggestionDropdown = ({isOpen, search}: {isOpen: boolean; search: string}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [collections, setCollections] = useState<CollectionListInterface[]>([]);
  const [items, setItems] = useState<ItemInterface[]>([]);
  const [accounts, setAccounts] = useState<UserInterface[]>([]);

  const getData = async () => {
    const data = await searchData({name: search});
    setCollections(data.collections);
    setItems(data.items);
    setAccounts(data.accounts);
    setLoading(false);
  };

  useEffect(() => {
    if (search !== '') {
      setLoading(true);
      const fetchData = setTimeout(() => {
        getData();
      }, 1000);
      return () => clearTimeout(fetchData);
    }
  }, [search]);

  return (
    <div className={'js-nav-dropdown group-dropdown relative'}>
      <div
        className={`dropdown-menu dark:bg-dark-navy group-dropdown-hover:opacity-100 group-dropdown-hover:visible !top-[85%] !left-auto z-50 min-w-full whitespace-nowrap rounded-xl bg-white before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 py-4 lg:px-4 lg:shadow-2xl lg:opacity-100 lg:max-h-auto max-h-[calc(100vh-150px)] overflow-y-scroll scrollbar-hide ${
          isOpen ? 'show visible' : 'invisible hidden'
        }`}
        style={{maxWidth: '-webkit-fill-available'}}
      >
        {loading ? (
          <LoadingSpinner />
        ) : accounts.length > 0 || collections.length > 0 || items.length > 0 ? (
          <>
            {accounts.length > 0 && (
              <div className="mb-4">
                <div className="text-grey text-xs mb-3">ACCOUNTS</div>
                <div>
                  {accounts.map((item, index) => {
                    return <SuggestionItem key={index} type="account" data={item} />;
                  })}
                </div>
              </div>
            )}

            {collections.length > 0 && (
              <div className="mb-4">
                <div className="text-grey text-xs mb-3">COLLECTIONS</div>
                <div>
                  {collections.map((item, index) => {
                    return <SuggestionItem key={index} type="collection" data={item} />;
                  })}
                </div>
              </div>
            )}

            {items.length > 0 && (
              <div className="mb-4">
                <div className="text-grey text-xs mb-3">ITEMS</div>
                <div>
                  {items.map((item, index) => {
                    return <SuggestionItem key={index} type="item" data={item} />;
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="text-base p-1">Data not found</div>
        )}
      </div>
    </div>
  );
};
