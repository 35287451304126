import axios from './axiosInstance';
import {AxiosResponse} from 'axios';
import {axiosAuthInstance} from './axiosInstanceAuth';
import {APIResponse} from './baseResponse';
import {UserInterface} from './user';
import {ItemActiveListing, ItemInterface} from './item';
import {Job} from 'bull';

interface CollectionPayload {
  asset_name?: Array<string>;
  collection_name?: Array<string>;
  category?: Array<string>;
  page: number;
  isCurated?: boolean;
}
export interface CreateCollectionResponseInterface {
  status: number;
  message: string;
  data: {
    slug: string;
  };
}

interface CreateCollectionPayloadInterface {
  data: FormData;
}

interface UpdateCollectionPayloadInterface extends CreateCollectionPayloadInterface {
  id: number;
}

export interface RoyaltyInterface {
  collection_id: number;
  deleted: boolean;
  id: number;
  percentage: number;
  wallet_address: string;
}

export interface CollectionResponseInterface {
  Creator: UserInterface;
  assets: Array<any>;
  banner_image: string;
  chainId: number;
  category_id: number;
  contract_address: string;
  createdAt: Date;
  creator_address: string;
  deleted: boolean;
  description: string;
  discord_link: string;
  display_theme: string;
  explicit_sensitive: boolean;
  featured_image: string;
  id: number;
  logo_image: string;
  medium_link: string;
  name: string;
  payment_token: string;
  royalty: Array<RoyaltyInterface>;
  slug: string;
  telegram_link: string;
  updatedAt: Date;
  url: string;
  website_link: string;
  isWatched: boolean;
  itemCount: number;
  itemUniqueCount: number;
  volume: string | null;
  watchCount: number | null;
  lastSale: string | null;
  floorPrice: string | null;
  items: ItemInterface[];
  ItemActiveListing?: ItemActiveListing;
  isVerified: boolean;
  isImported: boolean;
  importedContractInfo?: {
    isImportFinish: boolean;
  };
}

export interface GenerateSlugResponseInterface {
  status: number;
  message: string;
  slug: string;
}

export interface FilterDataCollectionInterface {
  trait_type: string;
  value: Array<string>;
  nusa_attribute_type: string;
}

export interface CollectionMetadataInterface {
  page: string;
  pageCount: number;
  perPage: number;
  totalCount: number;
}

export interface CollectionListInterface {
  message: string;
  metadata: CollectionMetadataInterface;
  records: Array<CollectionResponseInterface>;
  status: number;
}

export interface CollectionStatusInterface {
  floorPrice: number;
  volume: number;
  uniqueOwner: number;
}

export interface Category {
  id: number;
  name: string;
}

export interface WatchListInterface {
  collectionId: number;
  id: number;
  userId: number;
}

export interface RoyaltiRecordData {
  amount: string;
  collection: string;
  itemName: string;
  seller: UserInterface;
  buyer: UserInterface;
  date: string;
  currency: string;
}

export interface RoyaltiResponseInterface {
  status: number;
  message: string;
  metadata: CollectionMetadataInterface;
  records: RoyaltiRecordData[];
}

export interface SaleHistoryResponse {
  records: SaleHistory[];
  totalVolume: string;
  totalVolumePrevious: string;
  percentChanges: number;
}

export interface SaleHistory {
  date: string;
  price: string[];
}

export interface TopCollectionInterface {
  id: number;
  logo_image: string;
  featured_image: string;
  banner_image: string;
  name: string;
  slug: string;
  url: string;
  description: string;
  contract_address: string;
  website_link: string;
  discord_link: string;
  telegram_link: string;
  medium_link: string;
  display_theme: string;
  chainId: number;
  payment_token: string;
  creator_address: string;
  category_id: number;
  explicit_sensitive: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  watchCount: number;
  percentChanges: number;
  totalVolumeNow: number;
  floorPrice: number;
}

export interface CollectionActivitiesPaginatedInterface {
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<CollectionActivitiesInterface>;
}

export interface CollectionActivitiesInterface {
  event: string;
  createdAt: number;
  price: string;
  from: string;
  to: string;
  tokenId: number;
}

export enum SortRange {
  LAST7DAYS = 'LAST_7_DAYS',
  LAST30DAYS = 'LAST_30_DAYS',
  LAST60DAYS = 'LAST_60_DAYS',
  LAST90DAYS = 'LAST_90_DAYS',
}

export enum TopCollectionSortRange {
  LAST7DAYS = 'LAST_7_DAYS',
  LAST30DAYS = 'LAST_30_DAYS',
  LAST60DAYS = 'LAST_60_DAYS',
  LAST90DAYS = 'LAST_90_DAYS',
  LAST24HOURS = 'LAST_24_HOURS',
}

export const listSortRange = Object.values(SortRange);

// Get  Collection
export const getCollectionApi = async ({
  asset_name,
  collection_name,
  category,
  page,
  isCurated,
}: CollectionPayload) => {
  return axios
    .get('collection', {
      params: {
        asset_name,
        collection_name,
        category,
        page,
        isCurated,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getMyCollectionApi = async ({page}: {page: number}) => {
  return axiosAuthInstance
    .get('collection/my-collection', {
      params: {
        page,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCategoryApi = async (): Promise<Category[]> => {
  return axios
    .get('category')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createCollectionApi = async ({
  data,
}: CreateCollectionPayloadInterface): Promise<CreateCollectionResponseInterface> => {
  return axiosAuthInstance
    .post('collection/create/', data)
    .then((response: AxiosResponse<APIResponse<CreateCollectionResponseInterface>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCollectionApi = async ({
  id,
  data,
}: UpdateCollectionPayloadInterface): Promise<CreateCollectionResponseInterface> => {
  return axiosAuthInstance
    .patch(`collection/update/${id}`, data)
    .then((response: AxiosResponse<APIResponse<CreateCollectionResponseInterface>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCollectionBySlugApi = async ({
  slug,
}: {
  slug: string;
}): Promise<CollectionResponseInterface> => {
  return axiosAuthInstance
    .get(`collection/details/${slug}`)
    .then((response: AxiosResponse<APIResponse<CollectionResponseInterface>>) => response.data)
    .catch((error) => error.response);
};

export const getCollectionByIdApi = async ({
  id,
}: {
  id: number;
}): Promise<CollectionResponseInterface> => {
  return axiosAuthInstance
    .get(`collection/${id}`)
    .then((response: AxiosResponse<APIResponse<CollectionResponseInterface>>) => response.data)
    .catch((error) => error);
};

export const getCollectionStatusApi = async ({
  id,
}: {
  id: number;
}): Promise<CollectionStatusInterface> => {
  return axiosAuthInstance
    .get(`collection/status/${id}`)
    .then((response: AxiosResponse<APIResponse<CollectionStatusInterface>>) => response.data)
    .catch((err) => err);
};

export const deleteCollectionByIdApi = async ({id}: {id: number}): Promise<any> => {
  return axiosAuthInstance
    .patch(`collection/delete/${id}`)
    .then((response: AxiosResponse<APIResponse<any>>) => response.data)
    .catch((error) => error);
};

export const generateSlugApi = async ({
  name,
}: {
  name: string;
}): Promise<GenerateSlugResponseInterface> => {
  return axiosAuthInstance
    .post(`collection/slug`, {name})
    .then((response: AxiosResponse<APIResponse<GenerateSlugResponseInterface>>) => response.data)
    .catch((error) => error);
};

export const getFilterDataApi = async ({
  id,
}: {
  id: number;
}): Promise<Array<FilterDataCollectionInterface>> => {
  return axiosAuthInstance
    .get(`item/filter-data/${id}`)
    .then((res: AxiosResponse<APIResponse<Array<FilterDataCollectionInterface>>>) => res.data)
    .catch((err) => err);
};

export const postWatchListCollectionApi = async ({
  id,
}: {
  id: number;
}): Promise<WatchListInterface> => {
  return axiosAuthInstance
    .post(`collection/watchlist/${id}`)
    .then((res: AxiosResponse<APIResponse<WatchListInterface>>) => res.data)
    .catch((err) => err);
};

export const getWatchListApi = ({page}: {page: number}): Promise<CollectionListInterface> => {
  return axiosAuthInstance
    .get(`collection/watchlist`, {
      params: {
        page,
      },
    })
    .then((res: AxiosResponse<APIResponse<CollectionListInterface>>) => res.data)
    .catch((err) => err);
};

export const collectionRoyaltiHistory = async ({
  collectionId,
  page,
}: {
  collectionId: number;
  page: number;
}): Promise<RoyaltiResponseInterface> => {
  return axiosAuthInstance
    .get(`royalty`, {
      params: {
        collectionId,
        page,
      },
    })
    .then((res: AxiosResponse<APIResponse<RoyaltiResponseInterface>>) => res.data)
    .catch((err) => err);
};

export const collectionSaleHistory = async ({
  collectionId,
  range,
}: {
  collectionId: number | undefined;
  range: SortRange;
}): Promise<SaleHistoryResponse> => {
  return axiosAuthInstance
    .get(`marketplace/collection/price-history/${collectionId}`, {
      params: {
        sortRange: range,
      },
    })
    .then((res: AxiosResponse<SaleHistoryResponse>) => res.data)
    .catch((err) => err);
};

export const getTopCollections = async ({
  sort,
}: {
  sort: TopCollectionSortRange;
}): Promise<Array<TopCollectionInterface>> => {
  return axiosAuthInstance
    .get(`collection/top-collection`, {
      params: {
        sortRange: sort,
        sortBy: 'VOLUME',
      },
    })
    .then((res: AxiosResponse<APIResponse<Array<TopCollectionInterface>>>) => res.data)
    .catch((err) => err);
};

export const getCollectionActivities = async ({
  collectionId,
  page,
  event,
}: {
  collectionId: number;
  page: number;
  event: string;
}): Promise<CollectionActivitiesPaginatedInterface> => {
  return axiosAuthInstance
    .get(`collection/activities/${collectionId}`, {
      params: {
        event: event,
        page: page,
      },
    })
    .then(
      (response: AxiosResponse<APIResponse<CollectionActivitiesPaginatedInterface>>) =>
        response.data,
    )
    .catch((error) => error);
};

export const getCollectionsSearch = async ({
  q,
  page,
}: {
  q: string;
  page: number;
}): Promise<CollectionListInterface> => {
  return axiosAuthInstance
    .get(`search/collections/${q}`, {
      params: {
        page: page,
      },
    })
    .then((response: AxiosResponse<APIResponse<CollectionListInterface>>) => response.data)
    .catch((error) => error);
};

export const importCollection = async ({
  contractAddress,
  categoryId,
}: {
  contractAddress: string;
  categoryId: number;
}) => {
  return axiosAuthInstance
    .post(`collection/import-queue`, {
      contractAddress,
      categoryId,
    })
    .then((response: AxiosResponse<APIResponse<Job<any>>>) => response.data)
    .catch((error) => error);
};

export const getImportCollectionJobStatus = async ({jobId}: {jobId: number}) => {
  return axiosAuthInstance
    .get(`collection/import-queue/status/${jobId}`)
    .then((response: AxiosResponse<APIResponse<Job<any>>>) => response.data)
    .catch((error) => error);
};
