import {configureStore} from '@reduxjs/toolkit';
import collectionSlice from './collectionSlice';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import itemSlice from './itemSlice';
import jwtReducer from './jwtSlice';
import languageSlice from './languageSlice';
import notificationSlice from './notificationSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    jwt: jwtReducer,
    collection: collectionSlice,
    item: itemSlice,
    language: languageSlice,
    notification: notificationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
