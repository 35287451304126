const ExchangeVertical = ({
  width = 50,
  height = 50,
  fill = '#000000',
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 15.043v-7.043h-1v7.043l-3.646-3.646-0.707 0.707 4.853 4.853 4.854-4.854-0.707-0.707-3.647 3.647zM5.488 15.531h0.023l-0.011 0.012-0.012-0.012zM15.646 5.604l-3.646-3.647v7.096h-1v-7.096l-3.646 3.647-0.708-0.708 4.854-4.853 4.854 4.854-0.708 0.707z"
        fill={fill}
      />
    </svg>
  );
};

export default ExchangeVertical;
