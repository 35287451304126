import ipfsImage from './ipfs-image';

const resolveImage = (url: string, defaulturl?: string) => {
  if (!url && !defaulturl) return '/images/banner-default.png';
  if (!url) return defaulturl;

  if (url.startsWith('ipfs://')) {
    return ipfsImage(url);
  }
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `${process.env.NEXT_PUBLIC_UPLOAD_URL}${url}`;
};

export default resolveImage;
