import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

interface JwtState {
  token: string | null;
}

const initialState: JwtState = {
  token: null,
};

export const jwtSlice = createSlice({
  name: 'jwt',
  initialState,
  reducers: {
    setJwt: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const {setJwt} = jwtSlice.actions;
export const getJwt = (state: RootState) => state.jwt.token;
export default jwtSlice.reducer;
