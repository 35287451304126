import {axiosAuthInstance} from './axiosInstanceAuth';

export const getBackendVersion = async () => {
  const response = await axiosAuthInstance.get('/build-version');
  const version = await response.data;
  return version;
};

export const getFrontendVersion = async () => {
  const response = await fetch(`/api/app-version`);
  const version = await response.json();
  if (version.error) {
    throw new Error(version);
  }
  return version;
};
