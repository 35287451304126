import Navbar from './menu/navbar';
import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {ethers} from 'ethers';
import axios from 'axios';
import {useApp} from './providers/app-provider';
import dynamic from 'next/dynamic';

const Footer = dynamic(import('./menu/footer'), {
  ssr: false,
});

export default function Layout({children}: {children: React.ReactNode}) {
  const router = useRouter();
  const {jwt: token, user, setJWT} = useApp();
  const [address, setAddress] = useState('');

  const accountChanged = async () => {
    const provider = new ethers.providers.Web3Provider(window?.ethereum as any);
    await provider.send('eth_requestAccounts', []);

    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();
    const CONNECT_WALLET_MSG = `I want to login at ${new Date().toUTCString()}`;
    try {
      const signature = await signer.signMessage(CONNECT_WALLET_MSG);
      const resp = await axios.post(process.env.NEXT_PUBLIC_BACKEND_URL + '/api/v1/auth/login', {
        walletAddress: walletAddress,
        signature: signature,
        message: CONNECT_WALLET_MSG,
      });
      const token = resp.data.jwt;
      setJWT && setJWT(token);
      localStorage.setItem('JWT', token);
      if (signature && token) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window?.ethereum as any);
    const account = await provider.send('eth_requestAccounts', []);
    setAddress(account[0]);
  };

  useEffect(() => {
    if (token) {
      getActiveAddress();
    }
  }, [token]);

  useEffect(() => {
    if (token && user?.wallet_address) {
      (window?.ethereum as any)?.on('accountsChanged', accountChanged);
      if (address.toUpperCase() !== user?.wallet_address.toUpperCase()) {
        accountChanged();
      }
    }
    return () => {
      (window?.ethereum as any)?.removeListener('accountsChanged', accountChanged);
    };
  }, [token, address]);

  return (
    <>
      <Navbar />
      <main>{children}</main>
      {router.pathname !== '/404' && <Footer />}
    </>
  );
}
