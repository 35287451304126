import {getUserApi, UserInterface} from '@api/user';
import MakeOfferModal from '@components/modal/makeBidModal';
import {Language, useI18n} from 'hooks/useI18n';
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {getCookie, setCookie} from 'cookies-next';
import dynamic from 'next/dynamic';
import {CurrencyInterface, getCurrencies} from '@api/common';
import {Category, getCategoryApi} from '@api/collection';

const WalletModal = dynamic(import('@components/modal/wallet_modal'), {
  ssr: false,
});

const LoginModal = dynamic(import('@components/modal/login_modal'), {
  ssr: false,
});

const BidsModal = dynamic(import('@components/modal/bidsModal'), {
  ssr: false,
});

const BuyModal = dynamic(import('@components/modal/buyModal'), {
  ssr: false,
});

const ImportStatusModal = dynamic(import('@components/modal/import_status_modal'), {
  ssr: false,
});

export interface AppInterface {
  jwt: string | null;
  setJWT?: (jwt: string | null) => void;
  isModalOpen: boolean;
  setModalOpen: (status: boolean) => void;
  user: UserInterface | null;
  setUser?: (user: UserInterface | null) => void;
  isModalImportOpen: boolean;
  setImportModalOpen: (status: boolean) => void;
  language: Language | null;
  setLanguage?: (lang: Language) => void;
  walletShow: boolean;
  setWalletShow: (show: boolean) => void;
  loginShow: boolean;
  setLoginShow: (show: boolean) => void;
  bidShow: boolean;
  setBidShow: (show: boolean) => void;
  buyShow: boolean;
  setBuyShow: (show: boolean) => void;
  currencies: CurrencyInterface[];
  setCurrencies: (currencies: CurrencyInterface[]) => void;
  categories: Category[];
  setCategories: (categories: Category[]) => void;
  allCategories: Category[];
}

const AppContext = createContext<AppInterface>({
  isModalOpen: false,
  isModalImportOpen: false,
  user: null,
  jwt: null,
  language: 'en',
  walletShow: false,
  loginShow: false,
  bidShow: false,
  buyShow: false,
  currencies: [],
  categories: [],
  allCategories: [],
  setModalOpen: () => {},
  setImportModalOpen: () => {},
  setWalletShow: () => {},
  setLoginShow: () => {},
  setBidShow: () => {},
  setBuyShow: () => {},
  setCurrencies: () => {},
  setCategories: () => {},
});

export function AppProvider({children}: {children: ReactNode}) {
  const i18n = useI18n();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isImportOpen, setImportModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<UserInterface | null>(null);
  const [jwt, setJWT] = useState<string | null>(null);
  const [language, setLanguage] = useState<Language | null>(null);
  const [walletShow, setWalletShow] = useState<boolean>(false);
  const [loginShow, setLoginShow] = useState<boolean>(false);
  const [bidShow, setBidShow] = useState<boolean>(false);
  const [buyShow, setBuyShow] = useState<boolean>(false);
  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<CurrencyInterface[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);

  const getUser = async () => {
    const profile = await getUserApi();
    setUser(profile);
  };

  const getCategories = async () => {
    const categories = await getCategoryApi();
    setCategories(categories);
    setAllCategories([{id: 0, name: 'All'}, ...categories]);
  };

  useEffect(() => {
    const token = localStorage.getItem('JWT');
    const language = getCookie('lang') ?? 'en';
    if (token) {
      getUser();
      setJWT(token);
    }

    if (language) {
      setLanguage(language as Language);
    }

    setIsAppReady(true);

    getCurrencies({page: 1, sortBy: 'NAME'}).then((res) => {
      setCurrencies(res.records);
    });

    getCategories();
  }, []);

  useEffect(() => {
    if (!isAppReady) return;
    if (jwt) {
      localStorage.setItem('JWT', jwt);
      getUser();
    } else {
      localStorage.removeItem('JWT');
    }
  }, [jwt, isAppReady]);

  useEffect(() => {
    if (language) {
      setCookie('lang', language);
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <AppContext.Provider
      value={{
        jwt: jwt,
        isModalOpen: isOpen,
        isModalImportOpen: isImportOpen,
        user: user,
        language: language,
        walletShow: walletShow,
        loginShow: loginShow,
        bidShow: bidShow,
        buyShow: buyShow,
        currencies: currencies,
        categories: categories,
        allCategories: allCategories,
        setJWT(jwt) {
          setJWT(jwt);
        },
        setModalOpen: (status) => {
          setOpen(status);
        },
        setImportModalOpen: (status) => {
          setImportModalOpen(status);
        },
        setUser(user) {
          setUser(user);
        },
        setLanguage(lang) {
          setLanguage(lang);
        },
        setWalletShow: (show: boolean) => {
          setWalletShow(show);
        },
        setLoginShow: (show: boolean) => {
          setLoginShow(show);
        },
        setBidShow: (show: boolean) => {
          setBidShow(show);
        },
        setBuyShow: (show: boolean) => {
          setBuyShow(show);
        },
        setCurrencies: (currencies: CurrencyInterface[]) => {
          setCurrencies(currencies);
        },
        setCategories: (categories: Category[]) => {
          setCategories(categories);
          setAllCategories([{id: 0, name: 'All'}, ...categories]);
        },
      }}
    >
      {walletShow && (
        <WalletModal
          show={walletShow}
          onClose={() => {
            setWalletShow(false);
          }}
        />
      )}
      {loginShow && (
        <LoginModal
          show={loginShow}
          onClose={() => {
            setLoginShow(false);
          }}
        />
      )}
      {bidShow && (
        <BidsModal
          show={bidShow}
          onClose={() => {
            setBidShow(false);
          }}
        />
      )}
      {buyShow && (
        <BuyModal
          show={buyShow}
          onClose={() => {
            setBuyShow(false);
          }}
        />
      )}
      {children}
      {isOpen && (
        <MakeOfferModal
          listingId={0}
          show={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          user={user as UserInterface}
          onlyMaticWrap={true}
        />
      )}
      {isImportOpen && <ImportStatusModal show={isImportOpen} />}
    </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);
