import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_CMS + '/api',
  headers: {
    Authorization:
      'Bearer 7bae0bb9b7140b88c1379933b7eced839a2f4bbab6a0b3e03e9fe7f1cc4e7a651a9dca7216bd8a6d54e2245dce86870452ac447679dbcd541a6833145586d4abd8b4653de06bd12896bc449ea528fe93750e380e4f1bc6f241fb3310cc0674243eebba6ce097c4a3b4ab56b7b498ffd04b9907c2aaca3a1e343ee9e32493d2b6',
  },
});

export default instance;
