import {NotifDataLimit, notificationApi} from '@api/notificationApi';
import {UserInterface} from '@api/user';
import {useEffect, useRef, useState} from 'react';
import Image from 'next/image';
import {TextEllipsis} from 'neka-simple-utilities';
import {useAppSelector} from '@redux/hooks';
import {useDispatch} from 'react-redux';
import {setNotification} from '@redux/notificationSlice';
import resolveImage from '@helpers/resolve-image';
import Link from 'next/link';

const Notification = ({user}: {user: UserInterface | null}) => {
  const dotMenu = useRef<any>(null);
  const dispatch = useDispatch();

  const userId = user?.id;

  const [dropdownShow, setDropdownShow] = useState(false);
  const [notifData, setNotifData] = useState<any[]>([]);
  const {isNewNotif} = useAppSelector((state) => state.notification);

  useEffect(() => {
    const onMenuClick = (w: MouseEvent) => {
      if (dotMenu.current && dotMenu.current.contains(w.target)) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    };

    document.addEventListener('click', onMenuClick);

    () => {
      document.removeEventListener('click', onMenuClick);
    };
  }, []);

  const getNotifData = async () => {
    const data = await notificationApi({
      userId: Number(userId),
      page: 1,
      take: NotifDataLimit.TAKE_3,
    });

    if (!data) {
      setNotifData([]);
    } else {
      setNotifData(data as any as Array<any>);
    }
  };

  return (
    <>
      <div>
        <button
          ref={dotMenu}
          className="border-jacarta-100 js-dark-mode-trigger ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          aria-label="dark"
          onClick={() => {
            dispatch(setNotification(false)), getNotifData();
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:fill-white fill-dark-navy dark-mode-light mt-1 h-5 w-5 transition-colors group-hover:fill-white group-focus:fill-white"
          >
            <path d="M16.0501 12.999C15.982 12.917 15.9151 12.835 15.8495 12.7558C14.9472 11.6644 14.4012 11.0057 14.4012 7.91598C14.4012 6.31637 14.0186 5.00387 13.2643 4.01949C12.7081 3.29228 11.9563 2.74063 10.9654 2.33293C10.9526 2.32584 10.9412 2.31653 10.9317 2.30545C10.5753 1.11189 9.59995 0.3125 8.49991 0.3125C7.39987 0.3125 6.42493 1.11189 6.0685 2.30422C6.05899 2.31489 6.04776 2.3239 6.03528 2.33088C3.72282 3.28285 2.59899 5.10928 2.59899 7.91475C2.59899 11.0057 2.05389 11.6644 1.15073 12.7546C1.0851 12.8338 1.01825 12.9141 0.950162 12.9978C0.774288 13.2099 0.662857 13.468 0.629057 13.7414C0.595257 14.0149 0.640503 14.2923 0.75944 14.5408C1.01251 15.074 1.55186 15.405 2.16751 15.405H14.8368C15.4496 15.405 15.9853 15.0744 16.2391 14.5437C16.3586 14.2951 16.4043 14.0175 16.3708 13.7437C16.3372 13.4699 16.226 13.2115 16.0501 12.999ZM8.49991 18.6875C9.0926 18.687 9.67411 18.5261 10.1828 18.2219C10.6914 17.9177 11.1083 17.4815 11.389 16.9595C11.4023 16.9345 11.4088 16.9065 11.408 16.8782C11.4072 16.8499 11.3991 16.8223 11.3845 16.7981C11.3699 16.7739 11.3493 16.7538 11.3246 16.7399C11.3 16.726 11.2722 16.7187 11.2439 16.7188H5.75678C5.72845 16.7187 5.70058 16.7259 5.67587 16.7398C5.65117 16.7536 5.63048 16.7737 5.61582 16.7979C5.60116 16.8222 5.59303 16.8498 5.59221 16.8781C5.5914 16.9064 5.59793 16.9345 5.61118 16.9595C5.89194 17.4814 6.30871 17.9176 6.81729 18.2218C7.32586 18.526 7.90729 18.6869 8.49991 18.6875Z" />
          </svg>
          {isNewNotif == true ? (
            <div className="rounded-full fixed mt-2 ml-2 border-green h-2 w-2 bg-green"></div>
          ) : null}
        </button>
        <div
          className={
            dropdownShow
              ? 'z-50 dropdown-menu-end dark:bg-dark-navy right-[-40px] min-w-[400px] rounded-xl bg-white py-2 text-left shadow-xl text-dark-navy absolute show '
              : 'dropdown-menu-end dark:bg-dark-navy min-w-[200px] rounded-xl bg-white py-4 px-2 text-left shadow-xl text-dark-navy absolute hidden'
          }
        >
          {notifData.length !== 0 ? (
            <>
              <h3 className="dark:text-white font-display p-4 tracking-wide ">Notifications</h3>
              <ul className="mt-4 mb-2 text-sm ">
                {notifData?.map((data, i) => {
                  return (
                    <>
                      {data?.notification_type == 'Offer' && (
                        <li
                          key={i}
                          className={`flex border-b border-light-grey dark:border-opacity-10 px-3 py-5 ${
                            data?.is_seen ? 'dark:bg-dark-navy bg-white' : 'dark:bg-[#0D1A13]'
                          }`}
                        >
                          <div className="rounded-full h-10 w-10 mr-2">
                            <Image
                              src={`${resolveImage(
                                data?.notification_detail_offer.offeror.profile_picture,
                                '/images/user/user_avatar_new.png',
                              )}`}
                              width={40}
                              height={40}
                              objectFit={'cover'}
                              className="rounded-full "
                            ></Image>
                          </div>

                          <div className="block">
                            <p className="text-[14px] dark:text-white">
                              {data?.notification_detail_offer.offeror_wallet_address ==
                              user?.wallet_address ? (
                                <span className="font-display">You</span>
                              ) : (
                                <span className="font-display">
                                  {data?.notification_detail_offer.offeror.username
                                    ? data?.notification_detail_offer.offeror.username
                                    : TextEllipsis({
                                        text: data?.notification_detail_offer
                                          .offeror_wallet_address,
                                        position: 'middle',
                                        length: 4,
                                      })}{' '}
                                </span>
                              )}
                              <span> {data?.notification_detail_offer.notification_type}</span> {''}
                              <Link href={`/item/${data?.notification_detail_offer.item.id}`}>
                                <a className="cursor-pointer">
                                  <span className="font-light font-display">
                                    {data?.notification_detail_offer.item.name}
                                  </span>
                                </a>
                              </Link>
                            </p>
                            <span className="text-sm text-dark-grey align-top">
                              {data?.notification_detail_offer.createdAt_description}
                            </span>
                          </div>
                        </li>
                      )}

                      {data?.notification_type == 'Sale' && (
                        <li
                          key={i}
                          className={`flex border-b border-light-grey dark:border-opacity-10 px-3 py-5 ${
                            data?.is_seen ? 'dark:bg-dark-navy bg-white' : 'dark:bg-[#0D1A13]'
                          }`}
                        >
                          <div className="rounded-full h-10 w-10 mr-2">
                            {data?.notification_detail_sale.lister.wallet_address ==
                            user?.wallet_address ? (
                              <Image
                                src={`${resolveImage(
                                  data?.notification_detail_sale.lister.profile_picture,
                                  '/images/user/user_avatar_new.png',
                                )}`}
                                width={40}
                                height={40}
                                objectFit={'cover'}
                                className="rounded-full "
                              ></Image>
                            ) : (
                              <Image
                                src={`${resolveImage(
                                  data?.notification_detail_sale.buyer.profile_picture,
                                  '/images/user/user_avatar_new.png',
                                )}`}
                                width={40}
                                height={40}
                                objectFit={'cover'}
                                className="rounded-full "
                              ></Image>
                            )}
                          </div>

                          <div className="block">
                            <p className="text-[14px] dark:text-white font-display">
                              You
                              {data?.notification_detail_sale.lister_wallet_address !==
                              user?.wallet_address ? (
                                <span className="font-display-2"> succesfully buy</span>
                              ) : (
                                <span className="font-display-2"> succesfully sold</span>
                              )}
                              <Link href={`/item/${data?.notification_detail_sale.item.id}`}>
                                <a className="cursor-pointer">
                                  <span className="font-light font-display">
                                    &nbsp;
                                    {data?.notification_detail_sale.item.name}
                                  </span>
                                </a>
                              </Link>
                            </p>
                            <span className="text-[13px] text-dark-grey align-top">
                              {data?.notification_detail_sale.createdAt_description}
                            </span>
                          </div>
                        </li>
                      )}

                      {data?.notification_type == 'Bid' && (
                        <li
                          key={i}
                          className={`flex border-b border-light-grey dark:border-opacity-10 px-3 py-5 ${
                            data?.is_seen ? 'dark:bg-dark-navy bg-white' : 'dark:bg-[#0D1A13]'
                          }`}
                        >
                          <div className="rounded-full h-10 w-10 mr-2">
                            <Image
                              src={`${resolveImage(
                                data?.notification_detail_bid.bidder.profile_picture,
                                '/images/user/user_avatar_new.png',
                              )}`}
                              width={40}
                              height={40}
                              objectFit={'cover'}
                              className="rounded-full "
                            ></Image>
                          </div>

                          <div className="block">
                            {data?.notification_detail_bid.bidder_wallet_address ==
                            user?.wallet_address ? (
                              <p className="text-[14px] dark:text-white">
                                <span className="font-display">You</span>
                                <span> bid </span> {''}
                                <Link href={`/item/${data?.notification_detail_bid.item.id}`}>
                                  <a
                                    onClick={() => setDropdownShow(false)}
                                    className="cursor-pointer"
                                  >
                                    <span className="font-light font-display cursor-pointer">
                                      {data?.notification_detail_bid.item.name}
                                    </span>
                                  </a>
                                </Link>
                              </p>
                            ) : (
                              <p className="text-[14px] dark:text-white">
                                <span className="font-display">
                                  {data?.notification_detail_bid.bidder.username
                                    ? data?.notification_detail_bid.bidder.username
                                    : TextEllipsis({
                                        text: data?.notification_detail_bid.bidder_wallet_address,
                                        position: 'middle',
                                        length: 4,
                                      })}{' '}
                                </span>
                                <span> bid your auctioned item</span> {''}
                                <Link href={`/item/${data?.notification_detail_bid.item.id}`}>
                                  <a
                                    onClick={() => setDropdownShow(false)}
                                    className="cursor-pointer"
                                  >
                                    <span className="font-light font-display">
                                      {data?.notification_detail_bid.item.name}
                                    </span>
                                  </a>
                                </Link>
                              </p>
                            )}
                            <span className="text-[13px] text-dark-grey align-top">
                              {data?.notification_detail_bid.createdAt_description}
                            </span>
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
              <div className="min-h-[40px] flex items-center ">
                <a
                  href={`/notification`}
                  className="pl-6 text-sm font-display font-medium gradient-text tracking-wide cursor-pointer "
                >
                  View all notification
                </a>
              </div>
            </>
          ) : (
            <div>
              <h3 className="dark:text-white font-display p-4 tracking-wide ">Notifications</h3>

              <ul className="mt-4  dark:bg-[#0D1A13] text-sm  ">
                <li className="flex border-b border-light-grey dark:border-opacity-10 justify-center mbl:pr-8 lg:pr-0 py-5">
                  <h3 className="dark:text-white font-display ">No notifications at the moment</h3>
                </li>
              </ul>
              <div className="min-h-[40px] flex items-center ">
                <a
                  href={`/notification`}
                  className="pl-6 pt-2 text-sm font-display font-medium gradient-text tracking-wide cursor-pointer "
                >
                  View all notification
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
