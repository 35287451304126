/* eslint-disable @next/next/no-img-element */
import React, {useState, useEffect} from 'react';
import {useTheme} from 'next-themes';
import Link from 'next/link';
import MblNavbar from './mblNavbar';
import {useSelector, useDispatch} from 'react-redux';
import {openMblMenu} from '../../redux/counterSlice';
import {useRouter} from 'next/router';
import MblUserMenu from './mblUserMenu';
import Notification from './notification';
import {SuggestionDropdown} from '../search/suggestion_dropdown';
import {checkNewNotification} from '@api/notificationApi';
import {setNotification} from '@redux/notificationSlice';
import {useApp} from '../providers/app-provider';
import {useInterval} from 'hooks/use-interval';
import Beta from './beta';

const Navbar = () => {
  const {theme, setTheme} = useTheme();
  const [scroll, setScroll] = useState(false);
  const [search, setSearch] = useState('');
  const [showSuggestion, setShowSuggestion] = useState(false);

  const {user, jwt: token} = useApp();
  const {mblMenu} = useSelector((state: any) => state.counter);
  const dispatch = useDispatch();
  const [timeInterval, setTimeInterval] = useState(0);

  const NOTIFICATION_INTERVAL = process.env.NOTIFICATION_INTERVAL
    ? Number(process.env.NOTIFICAITION_INTERVAL)
    : 1000 * 90;

  const handleSticky = function () {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleTheme = () => {
    if (theme == 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  const router = useRouter();
  const pid = router.asPath;

  useEffect(() => {
    window.addEventListener('scroll', handleSticky);
  }, []);

  useEffect(() => {
    if (search !== '') setShowSuggestion(true);
    else setShowSuggestion(false);
  }, [search]);

  const removeSearch = () => {
    setSearch('');
  };

  const checkNotification = async () => {
    try {
      const notifData = await checkNewNotification({userId: user?.id as number});
      dispatch(setNotification(notifData.newNotification));
    } catch (err) {
      console.log('error while fetching api:', err);
    }
  };

  useInterval(async () => {
    const token = localStorage.getItem('JWT');
    if (token == null) return;
    checkNotification();
  }, NOTIFICATION_INTERVAL);

  /* setTimeout(() => {
    console.log({ NOTIFICATION_INTERVAL });
    setTimeInterval(timeInterval + 1);
  }, NOTIFICATION_INTERVAL); */

  /* useEffect(() => {
    const token = localStorage.getItem('JWT');
    if (token == null) return;
    checkNotification();
  }, [timeInterval]); */

  if (mblMenu) {
    return (
      <div>
        <header
          className={
            scroll
              ? 'js-page-header fixed top-0 z-50 w-full backdrop-blur transition-colors js-page-header--is-sticky h-full'
              : 'js-page-header fixed top-0 z-50 w-full backdrop-blur transition-colors h-full'
          }
        >
          {pid === '/platform_status' ? (
            <div className="container">
              <div className="flex items-center py-[1.5625rem] lg:py-[1.8125rem]">
                {/* <!-- Logo --> */}
                <Link href="/">
                  <a className="shrink-0 flex items-center lg:mr-14">
                    <img
                      src="/images/logo-light.png"
                      alt=""
                      className="max-h-7 h-auto dark:hidden"
                    />
                    <img
                      src="/images/logo-dark.png"
                      className="max-h-7 h-auto hidden dark:block"
                      alt="Nusa | NFT Marketplace"
                    />
                    {/*<Beta />*/}
                  </a>
                </Link>

                <span className="font-display mt-1 hidden text-lg font-semibold lg:inline-block">
                  Status
                </span>

                <Link href="#">
                  <a className="bg-accent shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all">
                    <span className="hidden lg:block">Subscribe to Updates</span>
                    <span className="lg:hidden">Subscribe</span>
                  </a>
                </Link>
              </div>
            </div>
          ) : (
            <div className="flex items-center px-6 py-6 xl:px-24 ">
              {/* <!-- Logo --> */}

              <Link href="/">
                <a className="shrink-0 block dark:flex items-center">
                  <img src="/images/logo-light.png" alt="" className="max-h-7 h-auto dark:hidden" />
                  {/*
                  <div className="font-bold dark:text-green-2 bg-grey/[0.3] ml-2 text-[10px] tracking-wide px-2 py-[2px] h-full rounded-xl font-display">
                    BETA
                  </div>
                  */}
                </a>
              </Link>
              <Link href="/">
                <a className="shrink-0 hidden dark:flex items-center">
                  <img
                    src="/images/logo-dark.png"
                    className="max-h-7 h-auto hidden dark:block"
                    alt="Nusa | NFT Marketplace"
                  />
                  {/*
                  <div className="font-bold dark:text-green-2 bg-grey/[0.3] ml-2 text-[10px] tracking-wide px-2 py-[2px] h-full rounded-xl font-display">
                    BETA
                  </div>
                  */}
                </a>
              </Link>

              {/* <!-- Search --> */}
              <form
                action="search"
                onSubmit={(e) => {
                  e.preventDefault();
                  setShowSuggestion(false);
                  router.push({
                    pathname: '/search',
                    query: {
                      q: search,
                    },
                  });
                }}
                className="relative ml-8 mr-4 hidden basis-6/12 lg:block "
              >
                <input
                  type="search"
                  className="text-dark-navy placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                  </svg>
                </span>
                {/* Dropdown Suggestion */}
                <SuggestionDropdown isOpen={showSuggestion} search={search} />
              </form>

              {/* <!-- Menu / Actions --> */}
              <MblNavbar theme={handleTheme} />

              {/* <!-- Mobile Menu Actions --> */}
              <div className="ml-auto flex lg:hidden">
                {/* <!-- Profile --> */}
                <Link href="/">
                  <a
                    className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                    aria-label="profile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-dark-navy h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                  </a>
                </Link>

                {/* <!-- Dark Mode --> */}
                <button
                  className="js-dark-mode-trigger border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                  onClick={handleTheme}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy dark-mode-light h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                  </svg>
                </button>

                {/* <!-- Mobile Menu Toggle --> */}
                <button
                  className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                  aria-label="open mobile menu"
                  onClick={() => dispatch(openMblMenu())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  } else {
    return (
      <div>
        <header
          className={
            scroll
              ? 'js-page-header fixed top-0 z-50 w-full backdrop-blur transition-colors js-page-header--is-sticky'
              : 'js-page-header fixed top-0 z-50 w-full backdrop-blur transition-colors'
          }
        >
          {pid === '/platform_status' ? (
            <div className="container">
              <div className="flex items-center py-[1.5625rem] lg:py-[1.8125rem]">
                {/* <!-- Logo --> */}
                <Link href="/">
                  <a className="shrink-0 flex lg:mr-14">
                    <img
                      src="/images/logo-light.png"
                      alt=""
                      className="max-h-7 h-auto dark:hidden"
                    />

                    <img
                      src="/images/logo-dark.png"
                      className="max-h-7 h-auto hidden dark:block"
                      alt="Nusa | NFT Marketplace"
                    />
                    {/*
                    <div className="font-bold dark:text-green-2 bg-grey/[0.3] ml-2 text-[10px] tracking-wide px-2 py-[2px] h-full rounded-xl font-display">
                      BETA
                    </div>
                    */}
                  </a>
                </Link>

                <span className="font-display mt-1 hidden text-lg font-semibold lg:inline-block">
                  Status
                </span>

                <Link href="#">
                  <a className="bg-accent shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all">
                    <span className="hidden lg:block">Subscribe to Updates</span>
                    <span className="lg:hidden">Subscribe</span>
                  </a>
                </Link>
              </div>
            </div>
          ) : (
            <div className="flex items-center px-6 py-6 xl:px-24 ">
              {/* <!-- Logo --> */}
              <Link href="/">
                <a className="shrink-0 flex items-center">
                  <img src="/images/logo-light.png" alt="" className="max-h-7 h-auto dark:hidden" />

                  <img
                    src="/images/logo-dark.png"
                    className="max-h-7 h-auto hidden dark:block"
                    alt="Nusa | NFT Marketplace"
                  />

                  {/*
                  <div className="font-bold dark:text-green-2 bg-grey/[0.3] ml-2 text-[10px] tracking-wide px-2 py-[2px] h-full rounded-xl font-display">
                    BETA
                  </div>
                  */}
                </a>
              </Link>

              {/* <!-- Search --> */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setShowSuggestion(false);
                  router.push({
                    pathname: '/search',
                    query: {
                      q: search,
                    },
                  });
                }}
                className="relative ml-12 mr-8 hidden basis-6/12 lg:block"
              >
                <input
                  type="search"
                  className="text-dark-navy placeholder-jacarta-500 focus:ring-green border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={() =>
                    search !== '' ? setShowSuggestion(true) : setShowSuggestion(false)
                  }
                  onBlur={() => setShowSuggestion(false)}
                />
                <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                  </svg>
                </span>
                {search !== '' && (
                  <span
                    className="absolute right-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl cursor-pointer"
                    onClick={removeSearch}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                    >
                      <g clipPath="url(#clip0_1415_32214)">
                        <path d="M10.8164 9.97248L14.8664 5.92248C14.9574 5.8162 15.005 5.67949 14.9996 5.53967C14.9942 5.39985 14.9362 5.26722 14.8373 5.16827C14.7383 5.06933 14.6057 5.01137 14.4659 5.00597C14.3261 5.00057 14.1894 5.04813 14.0831 5.13914L10.0331 9.18914L5.98308 5.13359C5.8768 5.04257 5.74009 4.99501 5.60027 5.00041C5.46045 5.00581 5.32782 5.06378 5.22888 5.16272C5.12994 5.26166 5.07197 5.39429 5.06657 5.53411C5.06117 5.67393 5.10873 5.81064 5.19975 5.91692L9.24975 9.97248L5.19419 14.0225C5.13603 14.0723 5.0888 14.1336 5.05545 14.2025C5.02211 14.2714 5.00337 14.3465 5.00041 14.423C4.99746 14.4995 5.01035 14.5758 5.03828 14.6471C5.06621 14.7184 5.10858 14.7831 5.16272 14.8373C5.21686 14.8914 5.28161 14.9338 5.3529 14.9617C5.42419 14.9896 5.50049 15.0025 5.577 14.9996C5.65351 14.9966 5.72858 14.9779 5.79751 14.9445C5.86643 14.9112 5.92772 14.864 5.97752 14.8058L10.0331 10.7558L14.0831 14.8058C14.1894 14.8968 14.3261 14.9444 14.4659 14.939C14.6057 14.9336 14.7383 14.8756 14.8373 14.7767C14.9362 14.6777 14.9942 14.5451 14.9996 14.4053C15.005 14.2655 14.9574 14.1288 14.8664 14.0225L10.8164 9.97248Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1415_32214">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                )}

                {/* Dropdown Suggestion */}
                <SuggestionDropdown isOpen={showSuggestion} search={search} />
              </form>

              {/* <!-- Menu / Actions --> */}
              <MblNavbar theme={handleTheme} />

              {/* <!-- Mobile Menu Actions --> */}
              <div className="ml-auto flex lg:hidden">
                {/* <!-- Profile --> */}
                <MblUserMenu />
                {token ? <Notification user={user} /> : null}
                {/* <!-- Dark Mode --> */}
                <button
                  className="border-jacarta-100 js-dark-mode-trigger ml-2 flex h-10 w-10 items-center justify-center rounded-full border  transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                  onClick={handleTheme}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy dark-mode-light h-4 w-4 transition-colors dark:hidden"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                  </svg>
                </button>

                {/* <!-- Mobile Menu Toggle --> */}

                <button
                  className="js-mobile-toggle border-jacarta-100 group ml-2 flex h-10 w-10 items-center justify-center rounded-full border  transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                  aria-label="open mobile menu"
                  onClick={() => {
                    dispatch(openMblMenu());
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-dark-navy h-4 w-4 transition-colors dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </header>

        {/* <Wallet_modal /> */}
      </div>
    );
  }
};

export default Navbar;
