import React from 'react';
import Head from 'next/head';

const Meta = ({
  title,
  keyword,
  desc,
  image,
}: {
  title?: string;
  keyword?: string;
  desc?: string;
  image?: string;
}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {image && <meta property="og:image" content={image} />}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Nusa NFT Marketplace',
  keyword:
    'bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: "The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
};

export default Meta;
