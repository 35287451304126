import '../styles/globals.css';
import {ThemeProvider} from 'next-themes';
import Layout from '../components/layout';
import {Provider} from 'react-redux';
import {store} from '../redux/store';
import {useRouter} from 'next/router';
import {MetaMaskProvider} from 'metamask-react';
import Meta from '../components/common/Meta';
import UserContext from '@components/providers/UserContext';
import {useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AppProps} from 'next/app';
import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum';
import {configureChains, createClient, WagmiConfig} from 'wagmi';
import {useI18n} from 'hooks/useI18n';

import {polygonMumbai, polygon} from 'wagmi/chains';
import {AppProvider} from '@components/providers/app-provider';
import {useHandleAppVersionChange} from 'hooks/use-handle-version-change';

import {Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query';

const DEEPLINK_URL = process.env.NEXT_PUBLIC_DEEPLINK_URL as string;

// eslint-disable-next-line react-hooks/rules-of-hooks
const {init} = useI18n();

init();

function web3ModalInit() {
  const chains = [polygonMumbai, polygon];
  //wallet connect config
  const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string;
  const {provider} = configureChains(chains, [
    w3mProvider({
      projectId,
    }),
  ]);
  const wagmiClient = createClient({
    autoConnect: false,
    connectors: w3mConnectors({projectId, version: 1, chains}),
    provider,
  });
  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);

  const web3ModalMobileWallets = [
    {
      id: 'metaMask',
      name: 'Metamask',
      links: {
        native: `dapp://${DEEPLINK_URL}/`,
        // universal: `https://metamask.app.link/dapp/${DEEPLINK_URL}`,
        universal: `dapp://${DEEPLINK_URL}/`,
      },
    },
    {
      id: 'coinbaseWallet',
      name: 'Coinbase',
      links: {
        native: `cbwallet://dapp?url=${encodeURIComponent(DEEPLINK_URL)}`,
        universal: `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(DEEPLINK_URL)}`,
      },
    },
  ];

  return {
    projectId,
    wagmiClient,
    ethereumClient,
    mobileWallets: web3ModalMobileWallets,
  };
}

function MyApp({Component, pageProps}: AppProps) {
  useHandleAppVersionChange();

  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  const {wagmiClient, ethereumClient, mobileWallets, projectId} = web3ModalInit();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  return (
    <>
      <Meta
        title="Indonesia's First NFT YIELD Marketplace | Nusa NFT Marketplace"
        desc="Find various NFT YIELD projects backed by real business and offering fixed return. Experience a new way earning from NFT without risking your investment on the speculative market."
      />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Provider store={store}>
            <ThemeProvider enableSystem={false} defaultTheme={'dark'} attribute="class">
              <MetaMaskProvider>
                <WagmiConfig client={wagmiClient}>
                  <UserContext.Provider value={{scrollRef: scrollRef}}>
                    <AppProvider>
                      {pid === '/login' ? (
                        <Component {...pageProps} />
                      ) : (
                        <Layout>
                          <Component {...pageProps} />
                          <ToastContainer />
                        </Layout>
                      )}
                    </AppProvider>
                  </UserContext.Provider>
                </WagmiConfig>
              </MetaMaskProvider>
              {/* <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeMode="dark"
                mobileWallets={mobileWallets}
              /> */}
            </ThemeProvider>
          </Provider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
