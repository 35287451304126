import axios from './axiosInstance';
import {AxiosError, AxiosResponse} from 'axios';
import {axiosAuthInstance} from './axiosInstanceAuth';
import {APIResponse} from './baseResponse';
import {getUserByWalletAddress, UserInterface} from './user';
import {apolloClient} from './appoloClient';
import {gql} from '@apollo/client';
import retry from 'async-retry';
import {mintBatch, minting} from '@helpers/web3';

import type {BigNumber, BigNumberish, BytesLike} from 'ethers';
import {axiosAuthInstanceV2} from './axiosInstanceAuthV2';
import {CurrencyInterface} from './common';

export type PromiseOrValue<T> = T | Promise<T>;

export type MintRequestStruct = {
  to: PromiseOrValue<string>;
  royaltyRecipient: PromiseOrValue<string>;
  royaltyBps: PromiseOrValue<BigNumberish>;
  primarySaleRecipient: PromiseOrValue<string>;
  tokenId: PromiseOrValue<BigNumberish>;
  uri: PromiseOrValue<string>;
  quantity: PromiseOrValue<BigNumberish>;
  pricePerToken: PromiseOrValue<BigNumberish>;
  currency: PromiseOrValue<string>;
  validityStartTimestamp: PromiseOrValue<BigNumberish>;
  validityEndTimestamp: PromiseOrValue<BigNumberish>;
  uid: PromiseOrValue<BytesLike>;
};

export interface AttributesInterface {
  id: number;
  trait_type: string | null;
  nusa_attribute_type: any;
  value: string | null;
  max_value: string | null;
  opensea_display_type: string | null;
  itemId: number;
}

export interface CollectionDetailInterface {
  banner_image: string;
  blockchain: number;
  category_id: number;
  contract_address: string | null;
  createdAt: Date;
  creator_address: string;
  deleted: boolean;
  description: string;
  discord_link: string | null;
  display_theme: string;
  explicit_sensitive: boolean;
  featured_image: string;
  id: number;
  logo_image: string;
  medium_link: string | null;
  name: string;
  payment_token: string;
  slug: string;
  telegram_link: string | null;
  updatedAt: Date;
  url: string | null;
  website_link: string | null;
  volume: string | null;
  watchCount: number | null;
  lastSale: string | null;
  royalty: {
    collection_id: number;
    deleted: boolean;
    id: number;
    percentage: number;
    wallet_address: string;
  }[];
  isVerified: boolean;
  isImported: boolean;
  importedContractInfo?: {
    isImportFinish: boolean;
  };
}

export interface ItemActiveListing {
  assetContract: string;
  buyoutPricePerToken: string;
  currency: string;
  endTime: number;
  id: number;
  itemId: number;
  listingId: number;
  listingType: ListingType;
  quantity: number;
  reservePricePerToken: string;
  startTime: number;
  tokenId: number;
  tokenType: string;
  highestOffer: any;
  offers: any;
  lister: {
    wallet_address: string;
    username: string;
    profile_picture: string;
  };
  isClosedByLister?: boolean;
  isClosedByBidder?: boolean;
  bids: any;
  isLazyMint: boolean;
  Currency: {
    id: number;
    address: string;
    chainId: string;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    name: string;
    symbol: string;
    decimals: number;
    logoUrl: string;
    tokenType: string;
  };
}

export enum ListingType {
  Direct = 'Direct',
  Auction = 'Auction',
}

export enum TokenType {
  ERC1155 = 'ERC1155',
  ERC721 = 'ERC721',
}

export enum SortRange {
  LAST7DAYS = 'LAST_7_DAYS',
  LAST30DAYS = 'LAST_30_DAYS',
  LAST60DAYS = 'LAST_60_DAYS',
  LAST90DAYS = 'LAST_90_DAYS',
}

export const listSortRange = Object.values(SortRange);

export interface LazyMintListingCreateInterface {
  itemId: number;
  assetContract: string;
  startTime: number;
  endTime: number;
  buyoutPricePerToken: string;
  reservePricePerToken: string;
  currency: string;
  quantity: number;
  listingType: ListingType;
  tokenType: string;
}

export interface LazyMintListingInterface {
  id: number;
  itemId: number;
  assetContract: string;
  startTime: number;
  endTime: number;
  buyoutPricePerToken: string;
  reservePricePerToken: string;
  currency: string;
  quantity: number;
  listingType: ListingType;
  tokenType: TokenType;
  isCancelled: boolean;
  highestOffer: any;
  lister: string;
  offers: any;
}

export interface ItemViewInterface {
  id: number;
  itemId: number;
  userId: number;
  ip: string;
  User: UserInterface;
}

export class Item implements ItemInterface {
  Collection: CollectionDetailInterface;
  id: number;
  uuid?: string;
  tokenId: number;
  name: string;
  description: string;
  external_link: string;
  image: string;
  collection_id: number;
  creator_address: string;
  creatorEarnings: string | undefined;
  contract_address: string | null;
  attributes: AttributesInterface[];
  metadata: string | null;
  unlockable: boolean;
  explicit_sensitive: boolean;
  supply: number;
  chainId: number;
  is_metadata_freeze: boolean;
  quantity_minted: number;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  itemLikeCount: number;
  itemViewCount: number;
  isLiked: boolean;
  token_standard: string;
  ItemActiveListings?: ItemActiveListing[];
  ActiveLazyMintListing?: LazyMintListingInterface;
  relatedItems: Array<ItemInterface>;
  owners: Array<OwnerItem>;
  ItemViews: Array<ItemViewInterface>;
  listings: any;
  voucherInfo?: {
    supply: number;
    redeemed: number;
  };
  isVoucherRedeemable: boolean;
  Creator: any;

  constructor(item: ItemInterface) {
    this.Collection = item.Collection;
    this.id = item.id;
    this.tokenId = item.tokenId;
    this.name = item.name;
    this.description = item.description;
    this.external_link = item.external_link;
    this.image = item.image;
    this.collection_id = item.collection_id;
    this.creator_address = item.creator_address;
    this.creatorEarnings = item.creatorEarnings;
    this.contract_address = item.contract_address;
    this.attributes = item.attributes;
    this.metadata = item.metadata;
    this.unlockable = item.unlockable;
    this.explicit_sensitive = item.explicit_sensitive;
    this.supply = item.supply;
    this.chainId = item.chainId;
    this.is_metadata_freeze = item.is_metadata_freeze;
    this.quantity_minted = item.quantity_minted;
    this.deleted = item.deleted;
    this.createdAt = item.createdAt;
    this.updatedAt = item.updatedAt;
    this.itemLikeCount = item.itemLikeCount;
    this.itemViewCount = item.itemViewCount;
    this.isLiked = item.isLiked;
    this.token_standard = item.token_standard;
    this.ItemActiveListings = item.ItemActiveListings;
    this.ActiveLazyMintListing = item.ActiveLazyMintListing;
    this.relatedItems = item.relatedItems;
    this.owners = item.owners;
    this.ItemViews = item.ItemViews;
    this.listings = item.listings;
    this.voucherInfo = item.voucherInfo;
    this.isVoucherRedeemable = item.isVoucherRedeemable;
    this.Creator = item.Creator;
  }

  get is_minted() {
    return this.supply == this.quantity_minted;
  }
}

export interface ItemInterface {
  listings: any;
  Collection: CollectionDetailInterface;
  id: number;
  uuid?: string;
  tokenId: number;
  name: string;
  description: string;
  external_link: string;
  image: string;
  collection_id: number;
  creator_address: string;
  creatorEarnings: string | undefined;
  contract_address: string | null;
  attributes: AttributesInterface[];
  metadata: string | null;
  unlockable: boolean;
  explicit_sensitive: boolean;
  supply: number;
  chainId: number;
  is_metadata_freeze: boolean;
  quantity_minted: number;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  itemLikeCount: number;
  itemViewCount: number;
  isLiked: boolean;
  token_standard: string;
  ItemActiveListings?: ItemActiveListing[];
  ActiveLazyMintListing?: LazyMintListingInterface;
  LazyMintListing?: LazyMintListingInterface[];
  relatedItems: Array<ItemInterface>;
  owners: Array<OwnerItem>;
  ItemViews: Array<ItemViewInterface>;
  voucherInfo?: {
    supply: number;
    redeemed: number;
  };
  isVoucherRedeemable: boolean;

  is_minted: boolean;
  Creator: any;

  optimizedImage?: string;
}

export interface MarketplaceSaleInterface {
  id: number;
  listingId: number;
  assetContract: string;
  lister: string;
  buyer: string;
  quantityBought: number;
  totalPricePaid: string;
  createdAt: number;
  transactionHash: string;
  duration: string;
}

export interface MarketplaceInterface {
  id: number;
  listingId: number;
  lister: string;
  tokenOwner: string;
  assetContract: string;
  tokenId: number;
  startTime: number;
  endTime: number;
  quantity: number;
  currency: string;
  reservePricePerToken: string;
  buyoutPricePerToken: string;
  tokenType: string;
  listingType: string;
  createdAt: number;
  updatedAt: number;
  isCancelled: boolean;
  isClosedByLister: any;
  isClosedByBidder: any;
  MarketplaceSale: MarketplaceSaleInterface;
}

export enum MintStatus {
  LAZY_MINT = 'LAZY_MINT',
  MINTED = 'MINTED',
}

export interface OwnerItem {
  profile_picture: string;
  quantity: number;
  username: string;
  wallet_address: string;
  mintStatus: MintStatus;
}
export interface ItemPaginatedInterface {
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<ItemInterface>;
}

export interface ItemInterfaceListed {
  type: 'listed';
  itemId: number;
  name: string;
  image: string;
  price: string;
  currency: string;
  currencyDecimals: number;
  currencyLogoUrl: string;
  currencySymbol: string;
  quantity: number;
  createdAt: number;
  duration: string;
  optimizedImage?: string;
}

export interface ItemListedPaginatedInterface {
  name?: string;
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<ItemInterfaceListed>;
}

export interface ItemInterfaceSold {
  type: 'sold';
  itemId: number;
  name: string;
  image: string;
  price: string;
  createdAt: number;
  duration: string;
  currency: string;
  currencyDecimals: number;
  currencyLogoUrl: string;
  currencySymbol: string;
  optimizedImage?: string;
}

export interface ItemSoldPaginatedInterface {
  name?: string;
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<ItemInterfaceSold>;
}

export interface OfferPaginatedInterface {
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<OfferInterface>;
}

export interface OfferInterface {
  id: BigNumber;
  floorDifference: string;
  offeror: string;
  fromAddress: string;
  expiration: string;
  price: string;
  currency: string;
  Currency: CurrencyInterface;
  totalOfferAmount: string;
  expirationTimestamp: number;
  pricePerToken: string;
  quantity: number;
  totalPrice: string;
}

export interface OfferItemInterface extends OfferInterface {
  isExpired: boolean;
}

export interface ItemActivitiesPaginatedInterface {
  status: number;
  message: string;
  metadata: {
    page: number;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: Array<ItemActivitiesInterface>;
}

export interface ItemActivitiesInterface {
  event: string;
  createdAt: number;
  price: string;
  from: {
    username?: string;
    wallet_address: string;
  };
  to: any;
  tokenId: number;
  contractAddress: string;
  currency: string;
  currencydecimals: number;
  date: string;
}

// interface CreateItemPayloadInterface {
//   data: FormData;
// }

export interface Attributes {
  trait_type: string;
  nusa_attribute_type: string;
  value: string;
  max_value: string;
}

export interface CreateItemPayloadInterface {
  name: string;
  description?: string;
  external_link?: string;
  collection_id: number;
  image: string;
  supply: number;
  unlockable: boolean;
  explicit_sensitive: boolean;
  is_metadata_freeze: boolean;
  attributes?: Array<Attributes>;
  chainId: number;
  is_unique: boolean;
  description_link_prefix: string;
}

export interface CreateItemResponseInterface {
  status: number;
  message: string;
  data: ItemInterface;
}

export interface LazyMintSignatureResponse {
  mintRequest: MintRequestStruct;
  signature: string;
}

export interface SaleHistoryResponse {
  totalVolume: string;
  totalVolumePrevious: string;
  percentChanges: number;
  records: SaleHistory[];
}

export interface SaleHistory {
  date: string;
  price: string[];
}

export enum ItemSortBy {
  RECENTLY_ADDED = 'RECENTLY_ADDED',
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  AUCTION_ENDING_SOON = 'AUCTION_ENDING_SOON',
  NAME_A_TO_Z = 'NAME_A_TO_Z',
  NAME_Z_TO_A = 'NAME_Z_TO_A',
}

export enum ItemFilter {
  NSFW_ONLY = 'nsfwOnly',
  LAZY_MINTED_ONLY = 'lazyMintedOnly',
}

export interface ItemOnSaleParameters {
  page?: number;
  categoryId?: number;
  category?: string;
  owner_address?: string;
  sortBy?: ItemSortBy;
  nsfwOnly?: boolean;
  lazyMintedOnly?: boolean;
  walletAddress?: string;
  owner?: string;
  search?: string;
}

export interface GetItemsFilter {
  collection_id?: number;
  search?: string;
  attribute?: any;
  page?: number;
  isMultiple?: boolean;
  isSingle?: boolean;
  listingType?: ListingType | null;
  isOnSale?: boolean;
  isRecentlySold?: boolean;
  hasOffers?: boolean;
  priceMin?: string;
  priceMax?: string;
  owner?: string;
  creator?: string;
  isFavorited?: boolean;
  favoritedBy?: string; // wallet address
  nsfwOnly?: boolean;
  lazyMintedOnly?: boolean;
  sortBy?: string;
  category?: string;
  categoryId?: number;
}

export interface GetItemDetailInput {
  collection_id?: number;
  search?: string;
  attribute?: any;
  page?: number;
  isMultiple?: boolean;
  isSingle?: boolean;
  listingType?: ListingType | null;
  isOnSale?: boolean;
  hasOffers?: boolean;
  priceMin?: string;
  priceMax?: string;
  owner?: string;
  creator?: string;
  isFavorited?: boolean;
  favoritedBy?: string; // wallet address
  nsfwOnly?: boolean;
  lazyMintedOnly?: boolean;
  sortBy?: string;
  category?: string;
  categoryId?: number;
}

export interface CreateNftBatchResponse {
  data: CreateNftBatchResponseData;
}

export interface CreateNftBatchResponseData {
  createdCount: {count: number};
  uris: string[];
  uuids: string[];
}

export const getItemOfferHistories = async (
  itemId: number,
  page: number,
): Promise<OfferPaginatedInterface | never> => {
  return axiosAuthInstance
    .get(`item/offer-history/${itemId}?/`, {params: {page}})
    .then((response: AxiosResponse<OfferPaginatedInterface>) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getItemActivities = async ({
  itemId,
  page,
  event,
}: {
  itemId: number;
  page: number;
  event: string;
}): Promise<ItemActivitiesPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item/activities/${itemId}`, {
      params: {
        page: page,
        event: event,
      },
    })
    .then((response: AxiosResponse<APIResponse<ItemActivitiesPaginatedInterface>>) => response.data)
    .catch((error) => error);
};

export const getItemsByCollectionId = ({
  collection_id,
  search,
  attribute,
  page,
}: {
  collection_id?: number;
  search: string;
  attribute: any;
  page: number;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item`, {
      params: {
        collectionId: collection_id,
        name: search,
        page: page,
        attributes: attribute,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getItemsByCollectionIdV2 = ({
  collection_id,
  search,
  attribute,
  page,
  isMultiple,
  isSingle,
  listingType,
  isOnSale,
  hasOffers,
  priceMin,
  priceMax,
  owner,
  creator,
  isFavorited,
  nsfwOnly,
  lazyMintedOnly,
  sortBy,
  categoryId,
}: GetItemDetailInput): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstanceV2
    .get(`item`, {
      params: {
        collectionId: collection_id,
        name: search,
        page: page,
        attributes: attribute,
        isMultiple: isMultiple,
        isSingle: isSingle,
        listingType: listingType,
        isOnSale: isOnSale,
        hasOffers: hasOffers,
        priceMin: priceMin,
        priceMax: priceMax,
        owner: owner,
        creator: creator,
        isFavorited: isFavorited,
        nsfwOnly: nsfwOnly,
        lazyMintedOnly: lazyMintedOnly,
        sortBy: sortBy,
        categoryId: categoryId,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getItemsBySlug = ({
  collection_id,
  search,
  attribute,
  page,
}: {
  collection_id?: number;
  search: string;
  attribute: any;
  page: number;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item`, {
      params: {
        collectionId: collection_id,
        name: search,
        page: page,
        attributes: attribute,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getItemById = async (id: number): Promise<ItemInterface | AxiosError> => {
  return axiosAuthInstanceV2
    .get('item/' + id)
    .then((response: AxiosResponse<ItemInterface>) => {
      return new Item(response.data);
    })
    .catch((error: AxiosError) => {
      return error;
    });
};
export const getPublicSignature = async (): Promise<any> => {
  return axiosAuthInstance
    .get('auth/get-public-mint-signature')
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getTokenOwner = async (
  contractAddress: string,
  tokenId: number,
): Promise<UserInterface | null> => {
  if (!contractAddress) {
    return null;
  }
  const tokenIdHex = tokenId.toString(16);
  const id = `${contractAddress.toLowerCase()}/0x${tokenIdHex}`;
  const {data} = await apolloClient.query({
    variables: {
      id,
    },
    query: gql`
      query ($id: ID!) {
        erc1155Token(id: $id) {
          id
          totalSupply {
            valueExact
          }
          balances {
            account {
              id
            }
            valueExact
          }
        }
      }
    `,
  });
  const balance = (data.erc1155Token.balances as Array<any>).find((x) => x.account != null);
  if (!balance) {
    return null;
  }

  const walletAddress = balance.account.id;
  const user = await getUserByWalletAddress(walletAddress);

  return user;
};

export const setLikedItem = async (itemId: number): Promise<any> => {
  return axiosAuthInstance
    .post(`item/like/${itemId}`)
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const setViewedItem = async (itemId: number): Promise<any> => {
  return axiosAuthInstance
    .get(`item/views/${itemId}`)
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteItem = async (itemId: number): Promise<any> => {
  return axiosAuthInstance
    .delete(`item/${itemId}`)
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const favoriteItemsApi = async ({
  userId,
}: {
  userId: number;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item/favorited/${userId}`)
    .then((response: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => response.data)
    .catch((error) => error);
};

export const ownedItemsApi = async ({
  userId,
}: {
  userId: number;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item/owned/${userId}`)
    .then((response: AxiosResponse<APIResponse<Array<ItemPaginatedInterface>>>) => response.data)
    .catch((error) => error);
};

export const createdItemsApi = async ({
  userId,
}: {
  userId: number;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstance
    .get(`item/created/${userId}`)
    .then((response: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => response.data)
    .catch((error) => error);
};

export const setActiveListing = async (itemId: number | any, data: any): Promise<any> => {
  return axiosAuthInstance
    .post(`item/set-active-listing/${itemId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const cancelListingApi = async (listingId: number | any): Promise<any> => {
  return axiosAuthInstance
    .post(`item/cancel-listing/${listingId}`)
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const createLazyMintListing = async (
  itemId: number,
  listingData: LazyMintListingCreateInterface,
): Promise<AxiosResponse<APIResponse<LazyMintListingInterface>>> => {
  return axiosAuthInstance
    .post(`item/create-lazymint-listing/${itemId}`, listingData)
    .then((response: AxiosResponse<APIResponse<LazyMintListingInterface>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const cancelLazyMintListing = async (
  listingId: number,
): Promise<AxiosResponse<APIResponse<LazyMintListingInterface>>> => {
  return axiosAuthInstance
    .post(`item/cancel-lazymint-listing/${listingId}`)
    .then((response: AxiosResponse<APIResponse<LazyMintListingInterface>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getLazyMintListingSignatureV2 = async (
  listingId: number,
  quantity: number,
): Promise<LazyMintSignatureResponse> => {
  return axiosAuthInstanceV2
    .get(`item/get-lazymint-listing-signature/${listingId}?quantity=${quantity}`)
    .then((response: AxiosResponse<APIResponse<LazyMintSignatureResponse>>) => response.data)
    .catch((error) => error);
};

export const getItemsOnSale = async (
  params: ItemOnSaleParameters,
): Promise<ItemPaginatedInterface> => {
  return axios
    .get(`item/on-sale`, {params})
    .then((response: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => response.data)
    .catch((error) => error);
};

export const getSaleHistory = async ({
  id,
  range,
}: {
  id: number;
  range: SortRange;
}): Promise<SaleHistoryResponse> => {
  return axios
    .get(`marketplace/item/price-history/${id}`, {
      params: {
        sortRange: range,
      },
    })
    .then((response: AxiosResponse<SaleHistoryResponse>) => {
      return response.data;
    })
    .catch((error) => error);
};

export const setMintedItemV2 = async (
  itemId: number,
  tokenId: number,
  quantityMinted: number,
): Promise<any> => {
  return axiosAuthInstanceV2
    .patch(`item/set-minted/${itemId}`, {
      tokenId,
      quantityMinted,
    })
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const lazyMintSale = async (listingId: number, quantity: number): Promise<any> => {
  return axiosAuthInstanceV2
    .post(`item/lazy-mint-sale`, {
      listingId,
      quantity,
    })
    .then((response: AxiosResponse<APIResponse<any>>) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getItemsV2 = ({
  collection_id,
  search,
  attribute,
  page,
  isMultiple,
  isSingle,
  listingType,
  isOnSale,
  isRecentlySold,
  hasOffers,
  priceMin,
  priceMax,
  owner,
  creator,
  isFavorited,
  favoritedBy,
  nsfwOnly,
  lazyMintedOnly,
  sortBy,
  categoryId,
}: GetItemsFilter): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstanceV2
    .get(`item`, {
      params: {
        collectionId: collection_id,
        name: search,
        page: page,
        attributes: attribute,
        isMultiple: isMultiple,
        isSingle: isSingle,
        listingType: listingType,
        isOnSale: isOnSale,
        isRecentlySold: isRecentlySold,
        hasOffers: hasOffers,
        priceMin: priceMin,
        priceMax: priceMax,
        owner: owner,
        creator: creator,
        isFavorited: isFavorited,
        favoritedBy: favoritedBy,
        nsfwOnly: nsfwOnly,
        lazyMintedOnly: lazyMintedOnly,
        sortBy: sortBy,
        categoryId: categoryId,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getRecentlySold = async (): Promise<ItemSoldPaginatedInterface> => {
  return axiosAuthInstanceV2
    .get(`item/recently-sold`, {
      params: {
        page: 1,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemSoldPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getRecentlyListed = async (): Promise<ItemListedPaginatedInterface> => {
  return axiosAuthInstanceV2
    .get(`item/recently-listed`, {
      params: {
        page: 1,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemListedPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

export const getSearchItem = async ({
  query,
  page,
  isMultiple,
  isSingle,
  listingType,
  isOnSale,
  hasOffers,
  priceMin,
  priceMax,
}: {
  query: string;
  page: number;
  isMultiple?: boolean;
  isSingle?: boolean;
  listingType?: ListingType | null;
  isOnSale?: boolean;
  hasOffers?: boolean;
  priceMin?: string;
  priceMax?: string;
  sortBy?: string;
}): Promise<ItemPaginatedInterface> => {
  return axiosAuthInstanceV2
    .get(`item`, {
      params: {
        name: query,
        page: page,
        isMultiple: isMultiple,
        isSingle: isSingle,
        listingType: listingType,
        isOnSale: isOnSale,
        hasOffers: hasOffers,
        priceMin: priceMin,
        priceMax: priceMax,
      },
    })
    .then((res: AxiosResponse<APIResponse<ItemPaginatedInterface>>) => res.data)
    .catch((error) => error);
};

const createItemFormDataPayload = ({
  name,
  description,
  external_link,
  collection_id,
  image,
  supply,
  unlockable,
  explicit_sensitive,
  is_metadata_freeze,
  attributes,
  chainId,
  is_unique,
  description_link_prefix,
}: CreateItemPayloadInterface) => {
  const formData: any = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('name', name);
  formData.append('description', description);
  if (external_link) formData.append('external_link', external_link);
  if (collection_id) formData.append('collection_id', collection_id);
  formData.append('supply', supply);
  formData.append('unlockable', unlockable);
  formData.append('explicit_sensitive', explicit_sensitive);
  formData.append('is_metadata_freeze', is_metadata_freeze);
  if (attributes) formData.append('attributes', JSON.stringify(attributes));
  formData.append('chainId', chainId);
  if (is_unique) formData.append('is_unique', is_unique);
  if (description_link_prefix) formData.append('description_link_prefix', description_link_prefix);

  return formData;
};

export const getItemByUuid = async (uuid: string) => {
  return axiosAuthInstanceV2
    .get(`item/uuid/${uuid}`)
    .then((response: AxiosResponse<ItemInterface>) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Poll backend to get item by uuid.
 * Polling is required becuase the item is not immediately available after minting.
 *
 * @param uuid
 * @returns item: ItemInterface
 */
export const pollGetItemByUuid = async (uuid: string) => {
  // let item: ItemInterface;
  const item: ItemInterface = await retry(
    async () => {
      console.log('polling getItemByUuid');
      const item = await getItemByUuid(uuid);
      if (!item) throw new Error('Item not found');
      return item;
    },
    {retries: 10},
  );
  console.log('polling getItemByUuid done');
  console.log({item});
  return item;
};

interface UploadItemMetadataResponse {
  ipfsUri: string;
  itemUuid: string;
}

interface UploadItemMetadataBatchResponse {
  ipfsUris: string[];
  itemUuids: string[];
}
/**
 * When creating item on chain, upload the metadata using this API.
 * Backend will return the IPFS hash of the metadata and the item UUID.
 *
 * This IPFS hash will be used to mint the item on chain.
 * The indexer will pick up the item minted on chain and record it in DB.
 * Storing the uuid in DB will allow us to retrieve the item from DB.
 *
 * @param formData FormData
 * @returns { ipfsUri, itemUuid }: { string, string }
 */
export const uploadItemMetadata = async (formData: FormData) => {
  return axiosAuthInstanceV2
    .post(`item/upload-metadata`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res: AxiosResponse<UploadItemMetadataResponse>) => res.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * When creating unique multiple items on chain, upload the metadata using this API.
 * Backend will return the list of IPFS hash of the metadata and the list of item UUIDs.
 *
 * This IPFS hash will be used to mint the item on chain.
 * The indexer will pick up the item minted on chain and record it in DB.
 * Storing the uuid in DB will allow us to retrieve the item from DB.
 *
 * @param formData FormData
 * @returns { ipfsUris, itemUuids }: { string[], string[] }
 */
export const uploadItemMetadataBatch = async (
  formData: FormData,
): Promise<{ipfsUris: string[]; itemUuids: string[]}> => {
  return axiosAuthInstanceV2
    .post(`item/upload-metadata`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res: AxiosResponse<UploadItemMetadataBatchResponse>) => res.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Creating item onchain involves:
 * - uploading image and metadata to IPFS via REST API (returns ipfsUri and itemUuid)
 * - minting NFT onchain via Blockchain RPC Call
 * - polling getItemByUuid until item is minted
 *
 * @param payload CreateItemPayloadInterface
 * @returns item: ItemInterface
 */
export const createItemOnChain = async (payload: CreateItemPayloadInterface) => {
  try {
    const formDataPayload = createItemFormDataPayload(payload);
    // Mint Batch
    if (payload.is_unique) {
      const {ipfsUris, itemUuids} = await uploadItemMetadataBatch(formDataPayload);
      await mintBatch({uris: ipfsUris});
      const item = await pollGetItemByUuid(itemUuids[0]);
      return item;
    }
    // Mint single
    const {ipfsUri, itemUuid} = await uploadItemMetadata(formDataPayload);
    await minting({qty: payload.supply, metadata: ipfsUri});
    const item = await pollGetItemByUuid(itemUuid);
    return item;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/**
 * Create Item using lazy minting.
 * Item is created in DB level only.
 * Not on blockchain
 *
 * Minting is done later on sale. using signature minting
 *
 * @param param0
 * @returns
 */
export const createItemLazyMint = async (
  payload: CreateItemPayloadInterface,
): Promise<ItemInterface | CreateNftBatchResponseData> => {
  const formData = createItemFormDataPayload(payload);
  return axiosAuthInstance
    .post('item', formData)
    .then((response: AxiosResponse<CreateItemResponseInterface | CreateNftBatchResponse>) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSupply = async ({itemId, quantity}: {itemId: number; quantity: number}) => {
  return axiosAuthInstanceV2
    .post(`item/add-supply/${itemId}`, {quantity})
    .then((response: AxiosResponse<CreateItemResponseInterface>) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};
