import {toast} from 'react-toastify';

export const notifySignout = () => {
  toast.success('Logout successful', {
    className: 'toast',
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
  });
};

export const notifyChangeChain = () => {
  const chainNames: Record<string, string> = {
    '0x13881': 'Mumbai Testnet',
    '0x89': 'Polygon',
  };
  let networkName = chainNames[process.env.NEXT_PUBLIC_BACKEND_CHAIN_ID as string];
  if (!networkName) {
    networkName = 'the right';
  }
  toast.warning(`Please change your network to ${networkName} network`, {
    className: 'toast dark:bg-black bg-white dark:text-white dark:border-dark-grey ',
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
  });
};

export const notifyLinkCopied = (type: string) => {
  toast.info(`Copied ${type}`);
};

export const notifyWatchList = (message: string, view: string, onViewClick: () => void) => {
  toast.success(
    <div className="flex justify-between w-full px-4">
      <span>{message}</span>
      <span
        className="text-blue-light"
        onClick={(e) => {
          e.preventDefault();
          onViewClick();
        }}
      >
        {view}
      </span>
    </div>,
    {
      className: 'toast',
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
    },
  );
};

export const notifyCloseAuction = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Close auction success', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Close auction failed', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyRedeemItem = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Redeem item success', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Redeem item failed', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyImport = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Import collection success', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Import collection failed', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyInvalidVoucher = () => {
  toast.error('Invalid voucher', {
    className: 'toast',
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
  });
};

export const notifyEventRegister = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Registration Successful!', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Registration Failed.', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyText = (isSuccess: boolean, text: string) => {
  isSuccess
    ? toast.success(text + ' Successful!', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error(text + ' Failed.', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyItemDeleted = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Item deleted successfully!', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Delete item Failed.', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};

export const notifyReportSubmitted = (isSuccess: boolean) => {
  isSuccess
    ? toast.success('Report submitted successfully!', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      })
    : toast.error('Submit report Failed.', {
        className: 'toast',
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
};
