import {useHover} from './modal/login_modal';
import axios from 'axios';
import {useEffect} from 'react';
import {useWeb3Modal} from '@web3modal/react';
import {useSignMessage, useAccount} from 'wagmi';
import {useApp} from './providers/app-provider';

export const clearWalletConnectCache = () => {
  localStorage.removeItem('wagmi.connected');
  localStorage.removeItem('wagmi.injected.shimDisconnect');
  localStorage.removeItem('wagmi.store');
  localStorage.removeItem('wagmi.wallet');
  localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
  localStorage.removeItem('walletconnect');
};

export const WalletConnectBtn = () => {
  const {setLoginShow} = useApp();
  const [buttonIsHovering, buttonHoverProps] = useHover();
  const {open} = useWeb3Modal();
  const {address, isConnected} = useAccount();
  const {jwt: token, setJWT} = useApp();

  const CONNECT_WALLET_MSG = `I want to login at ${new Date().toUTCString()}`;
  const {signMessage} = useSignMessage({
    message: CONNECT_WALLET_MSG,
    onSuccess: (signature) => loginToBackend(signature),
  });
  const loginToBackend = async (signature: string) => {
    try {
      const resp = await axios.post(process.env.NEXT_PUBLIC_BACKEND_URL + '/api/v1/auth/login', {
        walletAddress: address,
        signature: signature,
        message: CONNECT_WALLET_MSG,
      });

      const token = resp.data.jwt;
      setJWT && setJWT(token);
      if (token) {
        setLoginShow && setLoginShow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginWalletConnect = async () => {
    if (!isConnected && !token) {
      clearWalletConnectCache();
      open();
      return;
    }

    await signMessage();
  };

  useEffect(() => {
    const token = localStorage.getItem('JWT');
    if (!token) {
      signMessage();
    }
  }, [isConnected]);

  return (
    <button
      onClick={() => loginWalletConnect()}
      {...(buttonHoverProps as Record<string, unknown>)}
      className="js-wallet dark:bg-dark-grey bg-white dark:border-grey border-jacarta-100 text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 py-4 px-8 text-center font-semibold hover:bg-gradient-to-br dark:hover:bg-gradient-to-br from-green-3 to-green-2  hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent "
    >
      <img
        src="/images/wallets/wallet_connect_24.svg"
        className="mr-2.5 inline-block h-6 w-6"
        alt=""
      />
      <span className="transition duration-300 ease-in-out ">
        {buttonIsHovering ? 'Sign in with WalletConnect' : 'WalletConnect'}
      </span>
    </button>
  );
};
