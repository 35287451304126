import React, {useEffect, useState} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {useApp} from '@components/providers/app-provider';
import axios, {AxiosError} from 'axios';
import {ethers} from 'ethers';
import {notifyChangeChain} from '../common/toast';

export const useHover = () => {
  const [hovering, setHovering] = useState(false);
  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };
  return [hovering, onHoverProps];
};

interface ChainConfig {
  chainId: string;
  rpcUrls: string[];
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrls: string[];
}

const mumbaiTestNet: ChainConfig = {
  chainId: '0x13881',
  rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
  chainName: 'Mumbai Testnet',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
};

const polygonMainNet: ChainConfig = {
  chainId: '0x89',
  rpcUrls: ['https://rpc-mainnet.matic.network'],
  chainName: 'Matic Mainnet',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  blockExplorerUrls: ['https://polygonscan.com/'],
};

const Login_modal = ({show, onClose}: {show: boolean; onClose?: () => void}) => {
  const {setJWT} = useApp();
  const [ethereumAvailable, setEthereumAvailable] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const ethereum = window?.ethereum;
    setEthereumAvailable(!!ethereum);
  }, []);

  const switchChain = async (chainId: string) => {
    let networkConfig = {};
    if (process.env.NEXT_PUBLIC_BACKEND_CHAIN_ID === '0x13881') {
      networkConfig = mumbaiTestNet;
    }
    if (process.env.NEXT_PUBLIC_BACKEND_CHAIN_ID === '0x89') {
      networkConfig = polygonMainNet;
    }
    await window?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [networkConfig as ChainConfig],
    });
  };

  const login = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();
    const {chainId} = await provider.getNetwork();

    // envChain is hex formatted
    const envChain = process.env.NEXT_PUBLIC_BACKEND_CHAIN_ID as string;
    const envChainInt = parseInt(envChain, 16);

    // make sure user is on the right chain
    if (chainId !== envChainInt) {
      try {
        await switchChain(envChain);
      } catch (error) {
        notifyChangeChain();
        onClose && onClose();
        return;
      }
    }

    try {
      const CONNECT_WALLET_MSG = `I want to login at ${new Date().toUTCString()}`;
      const signature = await signer.signMessage(CONNECT_WALLET_MSG);
      const resp = await axios.post(process.env.NEXT_PUBLIC_BACKEND_URL + '/api/v1/auth/login', {
        walletAddress: walletAddress,
        signature: signature,
        message: CONNECT_WALLET_MSG,
      });

      const token = resp.data.jwt;
      setJWT && setJWT(token);
      onClose && onClose();
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError;
      const errorMessage =
        (axiosError.response?.data as {message: string}).message ||
        axiosError.message ||
        'Server error';
      setError(errorMessage);
    }
  };

  return (
    <div>
      {/* <!-- Wallet Modal --> */}
      <div className={show ? 'block modal fade show z-[999]' : 'fade modal hidden'}>
        <div className="modal-dialog">
          <div className="modal-content max-w-[850px] max-h-[599px] lg:w-[805px] lg:h-[566px] md:w-[425px] md:h-[566px] sm:w-[400px] sm:h-[599px] mbl:w-[360px] mbl:h-[599px] ">
            <div className=" flex items-center justify-center flex-col py-6">
              <div className="absolute top-0 right-0 py-6">
                <button type="button" className="btn-close" onClick={() => onClose && onClose()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>
              <div className="lg:py-6 mbl:py-10 mbl:mt-10">
                <Link href="/">
                  <a className="shrink-0 lg:mr-14 ">
                    <img
                      src="/images/logo-dark.png"
                      className=" lg:w-[278px] lg:h-[60px] mbl:w-[219px] mbl:h-[44px]"
                      alt="Nusa | NFT Marketplace"
                    />
                  </a>
                </Link>
              </div>

              <div className="w-full max-w-[25.625rem] max-h-full text-center px-2 pt-8 lg:mt-0 mbl:mt-2">
                <h1 className="text-jacarta-700 font-display mb-6 lg:text-4xl mbl:text-2xl dark:text-white">
                  Sign in
                </h1>
                <div className="mb-10">
                  <p className="dark:text-jacarta-300 mb-2 lg:text-[20px] mbl:text-base mbl:leading-5 ">
                    Connect your wallet. Ethereum provider enabled browser required.
                  </p>
                  <p className="text-red">{error}</p>
                </div>
                <div>
                  <button
                    disabled={!ethereumAvailable}
                    onClick={login}
                    className="dark:bg-dark-grey bg-white dark:border-grey border-jacarta-100 text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 py-4 px-8 text-center font-semibold hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                  >
                    <Image
                      src="/images/chains/MATIC.svg"
                      className="inline-block h-6 w-6"
                      alt=""
                      height={24}
                      width={24}
                    />
                    <span className="ml-2">Connect Wallet</span>
                  </button>
                </div>
                <div>
                  {!ethereumAvailable && (
                    <span className="text-red">
                      The browser you are using does not have ethereum provider enabled. Consider
                      using wallets like Metamask or Trust Wallet
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_modal;
