import axios from './axiosInstance';
import {AxiosResponse} from 'axios';
import {axiosAuthInstance} from './axiosInstanceAuth';
import {APIResponse} from './baseResponse';
import {MintStatus} from './item';

export enum Role {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface UserInterface {
  id: number;
  wallet_address: string;
  deleted: boolean;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  description: string;
  cover_picture: string;
  profile_picture: string;
  twitter_link: string;
  instagram_link: string;
  website_link: string;
  createdAt: Date;
  updatedAt: Date;
  mintStatus: MintStatus;
  roles: Role[];
}

export interface UpdateUserInterface {
  status: number;
  message: string;
  records: UserInterface;
}

interface UserEditPayloadInterface {
  data: FormData;
}

interface EditUserResponseInterface {
  status: number;
  message: string;
}

export interface CollectionAccountInterface {
  id: number;
  logo_image: string;
  featured_image: string;
  banner_image: string;
  name: string;
  slug: string;
  url: string;
  description: string;
  contract_address: string;
  website_link: string | null;
  discord_link: string | null;
  telegram_link: string | null;
  medium_link: string | null;
  display_theme: string;
  blockchain: number;
  payment_token: string;
  creator_address: string;
  category_id: number;
  explicit_sensitive: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  watchCount: number;
}

export interface AccountInterface {
  id: number;
  wallet_address: string;
  deleted: boolean;
  first_name: string;
  last_name: string;
  username: string;
  email: string | null;
  twitter_link: string | null;
  profile_picture: string | null;
  description: string | null;
  cover_picture: string | null;
  createdAt: Date;
  updatedAt: Date;
  collections: Array<CollectionAccountInterface>;
}

export interface MetaDataInterface {
  page: string;
  perPage: number;
  pageCount: number;
  totalCount: number;
}

export interface AccountResponseInterface {
  status: number;
  message: string;
  metadata: MetaDataInterface;
  records: Array<AccountInterface>;
}

// Check Username
export const checkUsernameApi = async ({username}: {username: string}) => {
  return axios
    .get(`users/check/${username}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUserApi = async (): Promise<UserInterface> => {
  return axiosAuthInstance
    .get('auth/profile')
    .then((response: AxiosResponse<APIResponse<UserInterface>>) => response.data)
    .catch((error) => error);
};

export const updatetUserApi = async ({
  data,
}: UserEditPayloadInterface): Promise<EditUserResponseInterface> => {
  return axiosAuthInstance
    .patch(`users/update-profile`, data)
    .then((response: AxiosResponse<APIResponse<EditUserResponseInterface>>) => response.data)
    .catch((error) => error);
};

export const getUserByWalletAddress = async (walletAddress: string): Promise<UserInterface> => {
  return axios
    .get('users/wallet/' + walletAddress)
    .then((response: AxiosResponse<APIResponse<UserInterface>>) => response.data)
    .catch((error) => error);
};

export const getSearchAccount = async ({
  query,
  page,
}: {
  query: string;
  page: number;
}): Promise<AccountResponseInterface> => {
  return axiosAuthInstance
    .get(`search/accounts/${query}`, {
      params: {
        page: page,
      },
    })
    .then((response: AxiosResponse<APIResponse<AccountResponseInterface>>) => response.data)
    .catch((error) => error);
};

export const patchUpdateUser = async ({data}: {data: FormData}): Promise<UpdateUserInterface> => {
  return axiosAuthInstance
    .patch(`users/update-profile`, data, {})
    .then((response: AxiosResponse<APIResponse<UpdateUserInterface>>) => response.data)
    .catch((error) => error);
};
