import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface CollectionItem {
  id: number;
  logo_image: string;
  featured_image: string;
  banner_image: string;
  name: string;
  slug: string;
  url: string;
  description: string;
  contract_address: string;
  link: Array<string>;
  blockchain: number;
  payment_token: string;
  creator_address: string;
  category_id: number;
  explicit_sensitive: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  Creator: CreatorCollection;
  itemCount: number;
}

export interface CreatorCollection {
  id: number;
  wallet_address: string;
  deleted: boolean;
  first_name: string;
  last_name: string;
  username: string;
  profile_picture: string | null;
  description: string | null;
  cover_url: string;
  social_media_url: {};
  createdAt: Date;
  updatedAt: Date;
}

interface CollectionState {
  isLoadingCollection: boolean;
  collectionList: Array<CollectionItem>;
  pageCollection: number;
  pageCountCollection: number;
  reloadCollection: boolean;
  filterCategoryCollection: string;
  collectionDetail: CollectionItem | null;
}

const initialState: CollectionState = {
  isLoadingCollection: false,
  collectionList: [],
  pageCollection: 1,
  pageCountCollection: 1,
  reloadCollection: false,
  filterCategoryCollection: '',
  collectionDetail: null,
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    setLoadingCollection: (state: CollectionState, action: PayloadAction<boolean>) => {
      state.isLoadingCollection = action.payload;
    },
    setPageCollection: (state: CollectionState, action: PayloadAction<number>) => {
      state.pageCollection = action.payload;
    },
    setPageCountCollection: (state: CollectionState, action: PayloadAction<number>) => {
      state.pageCountCollection = action.payload;
    },
    setCollection: (state: CollectionState, action: PayloadAction<Array<CollectionItem> | []>) => {
      state.collectionList = [...state.collectionList, ...action.payload];
      state.isLoadingCollection = false;
    },
    resetCollection: (state: CollectionState) => {
      state.pageCollection = 1;
      state.pageCountCollection = 1;
      state.collectionList = [];
      state.filterCategoryCollection = '';
    },
    setReloadCollection: (state: CollectionState, action: PayloadAction<boolean>) => {
      state.reloadCollection = action.payload;
    },
    setFilterCategoryCollection: (state: CollectionState, action: PayloadAction<string>) => {
      state.filterCategoryCollection = action.payload;
    },
    setCollectionDetail: (state: CollectionState, action: PayloadAction<CollectionItem>) => {
      state.collectionDetail = action.payload;
      state.isLoadingCollection = false;
    },
  },
});

export const {
  setLoadingCollection,
  setPageCollection,
  setCollection,
  setReloadCollection,
  setFilterCategoryCollection,
  setCollectionDetail,
  setPageCountCollection,
  resetCollection,
} = collectionSlice.actions;
export default collectionSlice.reducer;
