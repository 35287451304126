import {useEffect, useRef} from 'react';

export function useInterval(callback: () => {}, delay: number) {
  const savedCallback = useRef<() => {} | undefined>();

  // Remember last callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay != null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}
