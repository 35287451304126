import {useMetaMask} from 'metamask-react';
import {useState, useEffect} from 'react';

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_BACKEND_CHAIN_ID as string;

export function useForceCorrectNetwork(): () => Promise<void> {
  const {chainId: metamaskChainId, switchChain} = useMetaMask();
  const [isRightNetwork, setIsRightNetwork] = useState<boolean>(false);
  const [appChainId] = useState<number>(parseInt(DEFAULT_CHAIN_ID));

  useEffect(() => {
    if (appChainId === parseInt(metamaskChainId as string)) {
      setIsRightNetwork(true);
      return;
    }
    setIsRightNetwork(false);
  }, [metamaskChainId]);

  async function forceCorrectNetwork() {
    if (!isRightNetwork) {
      await switchChain(DEFAULT_CHAIN_ID);
    }
  }

  return forceCorrectNetwork;
}
