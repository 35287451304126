import {ItemInterface} from '@api/item';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ItemState {
  newItem: ItemInterface | null;
}

const initialState: ItemState = {
  newItem: null,
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setNewItem: (state, action: PayloadAction<ItemInterface | null>) => {
      state.newItem = action.payload;
    },
  },
});

export const {setNewItem} = itemSlice.actions;
export default itemSlice.reducer;
