import resolveImage from '@helpers/resolve-image';
import {TextEllipsis} from 'neka-simple-utilities';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const SuggestionItem = ({type, data}: {type: string; data?: any}) => {
  if (type === 'account') {
    return (
      <a href={`/user/${data?.wallet_address}`}>
        <div className="flex items-center gap-x-4 p-1 cursor-pointer dark:hover:bg-white/[.10] hover:bg-black/[.10]">
          <div className="w-[50px] h-[50px] flex items-center">
            <Image
              src={`${resolveImage(data?.profile_picture, '/images/user/user_avatar_new.png')}`}
              height={50}
              width={50}
              alt="owner"
              className="rounded-full w-full"
              objectFit="cover"
            />
          </div>
          <div className="w-full  whitespace-nowrap overflow-hidden ">
            <div className="block text-ellipsis">
              <span className="font-display-2 hover:text-accent text-dark-navy text-base leading-none dark:text-white">
                {data?.username
                  ? `${data?.username}`
                  : TextEllipsis({
                      text: data?.wallet_address as any,
                      position: 'middle',
                      length: 4,
                    })}
              </span>
            </div>
          </div>
        </div>
      </a>
    );
  }
  if (type === 'collection') {
    return (
      <a href={`/collection/${data?.slug}`}>
        <div className="flex items-center gap-x-4 p-1 cursor-pointer dark:hover:bg-white/[.10] hover:bg-black/[.10]">
          <div className="w-[50px] h-[50px] flex items-center">
            <Image
              src={`${resolveImage(data?.banner_image)}`}
              height={50}
              width={50}
              alt="owner"
              className="rounded-md w-full"
              objectFit="cover"
              unoptimized
            />
          </div>
          <div className="w-full  whitespace-nowrap overflow-hidden ">
            <div className="block text-ellipsis">
              <span className="font-display-2 hover:text-accent text-dark-navy text-base leading-none dark:text-white">
                {data?.name}
              </span>
            </div>
            <div className="font-display-2 text-dark-navy dark:text-white text-sm">
              {data?.Creator?.username ??
                TextEllipsis({
                  text: data?.Creator?.wallet_address as any,
                  position: 'middle',
                  length: 4,
                })}
            </div>
          </div>
        </div>
      </a>
    );
  }
  return (
    <a href={`/item/${data?.id}`}>
      <div className="flex items-center gap-x-4 p-1 cursor-pointer dark:hover:bg-white/[.10] hover:bg-black/[.10]">
        <div className="w-[50px] h-[50px] flex items-center">
          <Image
            src={`${resolveImage(data?.image, '/images/banner-default.png')}`}
            height={50}
            width={50}
            alt="owner"
            className="rounded-md w-full"
            objectFit="cover"
            unoptimized
          />
        </div>

        <div className="w-full whitespace-nowrap overflow-hidden ">
          <div className="block text-ellipsis">
            <span className="font-display-2 hover:text-accent text-dark-navy text-base leading-none dark:text-white">
              {data?.name}
            </span>
          </div>
          <div className="font-display-2 text-dark-navy dark:text-white text-sm">
            {data?.Creator?.username
              ? `@${data?.Creator?.username}`
              : TextEllipsis({
                  text: data?.Creator?.wallet_address as any,
                  position: 'middle',
                  length: 4,
                })}
          </div>
        </div>
      </div>
    </a>
  );
};

export default SuggestionItem;
