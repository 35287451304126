import {BigNumber, ethers, providers} from 'ethers';
import NusaNFT from '@helpers/contracts/NusaNFT.json';
import Marketplace from '@helpers/contracts/MarketplaceFacet.json';
import Offers from '@helpers/contracts/OffersFacet.json';
import WMATIC_abi from 'helpers/contracts/WMATIC_abi.json';
import {MintRequestStruct} from '@api/item';
import axios from '../api/axiosInstance';
import {
  MarketplaceFacet,
  NusaNFT as NusaNFTType,
  OffersFacet,
} from '../types/typechain-types/index';
import {OfferParamsStruct} from 'types/typechain-types/contracts/facets/OffersFacet';

export enum ListingType {
  Direct = 0,
  Auction = 1,
}

const getMaticGasFees = async () => {
  // TODO: set isProd from .env
  const isProd = false;
  const {data} = await axios({
    method: 'get',
    url: isProd
      ? 'https://gasstation-mainnet.matic.network/v2'
      : 'https://gasstation-mumbai.matic.today/v2',
  });
  const maxFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxFee) + '', 'gwei');
  const maxPriorityFeePerGas = ethers.utils.parseUnits(
    Math.ceil(data.fast.maxPriorityFee) + '',
    'gwei',
  );

  return {maxFeePerGas, maxPriorityFeePerGas};
};

export const WETH_ABI = [
  'function deposit() public payable',
  'function withdraw(uint wad) public',
  'function balanceOf(address owner) view returns (uint)',
  'function allowance(address owner, address spender) view returns (uint)',
  'function approve(address spender, uint256 amount) returns (bool)',
];

const contractNFT = async () => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    process.env.NEXT_PUBLIC_NFT_CONTRACT_ADDRESS as string,
    NusaNFT.abi as any,
    signer,
  ) as NusaNFTType;

  return {contract, provider, signer};
};

const contractMarketplace = async () => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS as string,
    Marketplace.abi as any,
    signer,
  ) as MarketplaceFacet;

  return {contract, provider};
};

const contractWrappedMatic = async () => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    process.env.NEXT_PUBLIC_WRAPPED_MATIC_CONTRACT_ADDRESS as string,
    WMATIC_abi,
    signer,
  );

  return {contract, provider};
};

const contractERC20 = async (address: string) => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(address, WMATIC_abi, signer);

  return {contract, provider};
};

const contractOffers = async () => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS as string,
    Offers.abi as any,
    signer,
  ) as OffersFacet;

  return {contract, provider};
};

export const getListing = async (listingId: number) => {
  const {contract} = await contractMarketplace();
  const listing = await contract.getListing(listingId);
  return listing;
};

export const minting = async ({qty, metadata}: any) => {
  const {contract, signer} = await contractNFT();
  const address = await signer.getAddress();

  const tx = await contract.mintTo(address, ethers.constants.MaxUint256, metadata, qty);
  const receipt = await tx.wait();

  const data = receipt.events?.find((item: any) => item.event === 'TransferSingle');
  return data?.args?.id?.toNumber(); // tokenId;
};

export const mintBatch = async ({uris}: {uris: string[]}) => {
  const {contract, signer} = await contractNFT();
  const address = await signer.getAddress();

  const tokenIds = uris.map(() => ethers.constants.MaxUint256);
  const amounts = uris.map(() => 1);
  const tx = await contract.batchMintTo(address, tokenIds, amounts, uris);
  const receipt = await tx.wait();

  const data = receipt.events?.find((item: any) => item.event === 'TransferSingle');
  return data?.args?.id?.toNumber(); // tokenId;
};

export const mintWithSignature = async (mintRequest: MintRequestStruct, signature: string) => {
  const {contract} = await contractNFT();
  const value = ethers.BigNumber.from(mintRequest.pricePerToken)
    .mul(Number(mintRequest.quantity))
    .toString();
  const tx = await contract.mintWithSignature(mintRequest, signature, {value});
  const receipt = await tx.wait();

  const data = receipt.events?.find((item: any) => item.event === 'TransferSingle');
  return data?.args?.id?.toNumber(); // tokenId;
};

export const approvalForAll = async () => {
  const {contract} = await contractNFT();
  const tx = await contract.setApprovalForAll(
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS as string,
    true,
  );
  await tx.wait();
};

export const isApprovedForAll = async (owner: string) => {
  const {contract} = await contractNFT();
  const approved = await contract.isApprovedForAll(
    owner,
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS as string,
  );
  return approved;
};

/**
 * @param param0 ListingParameters
 *  @dev For use in `createListing` as a parameter type.
 *
 *  @param assetContract         The contract address of the NFT to list for sale.
 
 *  @param tokenId               The tokenId on `assetContract` of the NFT to list for sale.
 
 *  @param startTime             The unix timestamp after which the listing is active. For direct listings:
 *                                'active' means NFTs can be bought from the listing. For auctions,
 *                                'active' means bids can be made in the auction.
 *
 *  @param secondsUntilEndTime   No. of seconds after `startTime`, after which the listing is inactive.
 *                                For direct listings: 'inactive' means NFTs cannot be bought from the listing.
 *                                For auctions: 'inactive' means bids can no longer be made in the auction.
 *
 *  @param quantityToList        The quantity of NFT of ID `tokenId` on the given `assetContract` to list. For
 *                                ERC 721 tokens to list for sale, the contract strictly defaults this to `1`,
 *                                Regardless of the value of `quantityToList` passed.
 *
 *  @param currencyToAccept      For direct listings: the currency in which a buyer must pay the listing's fixed price
 *                                to buy the NFT(s). For auctions: the currency in which the bidders must make bids.
 *
 *  @param reservePricePerToken  For direct listings: this value is ignored. For auctions: the minimum bid amount of
 *                                the auction is `reservePricePerToken * quantityToList`
 *
 *  @param buyoutPricePerToken   For direct listings: interpreted as 'price per token' listed. For auctions: if
 *                                `buyoutPricePerToken` is greater than 0, and a bidder's bid is at least as great as
 *                                `buyoutPricePerToken * quantityToList`, the bidder wins the auction, and the auction
 *                                is closed.
 *
 *  @param listingType           The type of listing to create - a direct listing or an auction.
 *                                0 = Direct
 *                                1 = Auction
 * 
 *  @param listingRoyalty         struct ListingRoyaltyParameters {
 *                                  address[] recipients;
 *                                  uint64[] bpsPerRecipients;
 *                                }
 *
 *  @returns 
 */
export const listing = async ({
  assetContract,
  tokenId,
  secondsUntilEndTime,
  quantityToList,
  currencyToAccept,
  reservePricePerToken,
  buyoutPricePerToken,
  listingType,
  royaltyParams,
}: {
  assetContract: string;
  tokenId: number;
  secondsUntilEndTime: number;
  quantityToList: number;
  currencyToAccept: string;
  reservePricePerToken: string;
  buyoutPricePerToken: string;
  listingType: number;
  royaltyParams: ListingRoyaltyParameters;
}) => {
  const {contract, provider} = await contractMarketplace();

  const blockNumber = await provider.getBlockNumber();
  const timestamp = (await provider.getBlock(blockNumber)).timestamp;

  const createListingParams = {
    assetContract,
    tokenId,
    startTime: timestamp,
    secondsUntilEndTime,
    quantityToList,
    currencyToAccept,
    reservePricePerToken,
    buyoutPricePerToken,
    listingType,
    royaltyParams,
  };

  // const { maxFeePerGas, maxPriorityFeePerGas } = await getMaticGasFees();

  // const gasEstimate = await contract.estimateGas.createListing(createListingParams, { gasLimit: 100000 });
  // console.log({ gasEstimate })

  const tx = await contract.createListing(createListingParams);

  const receipt = await tx.wait();
  return {data: receipt, startTime: timestamp};
};

export interface ListingRoyaltyParameters {
  recipients: string[];
  bpsPerRecipients: number[];
}

export const cancelListing = async (_listingId: any) => {
  const {contract} = await contractMarketplace();

  const tx = await contract.cancelListing(_listingId);

  const receipt = await tx.wait();
  return receipt;
};

export const buyNFT = async ({
  _listingId,
  _buyFor,
  _quantityToBuy,
  _currency,
  _totalPrice,
  _value,
}: any) => {
  const {contract} = await contractMarketplace();

  const tx = await contract.buy(
    _listingId,
    _buyFor,
    _quantityToBuy,
    _currency,
    _totalPrice,
    _value,
  );

  const receipt = await tx.wait();
  console.log(receipt);
  return receipt;
};

export const buyNFTNonMatic = async ({
  _listingId,
  _buyFor,
  _quantityToBuy,
  _currency,
  _totalPrice,
}: any) => {
  const {contract} = await contractMarketplace();

  const tx = await contract.buy(_listingId, _buyFor, _quantityToBuy, _currency, _totalPrice);

  const receipt = await tx.wait();
  console.log(receipt);
  return receipt;
};

interface OfferInterface {
  listingId: number;
  quantityWanted: number;
  currency: string;
  pricePerToken: string;
  expirationTimestamp: number; // Epoch
  listingType: ListingType;
}

export const closeAuction = async ({
  listingId,
  closeFor,
}: {
  listingId: number;
  closeFor: string;
}) => {
  const {contract} = await contractMarketplace();

  const tx = await contract.closeAuction(listingId, closeFor);
  const receipt = await tx.wait();
  console.log(receipt);

  return receipt;
};

export const getAuctionEndTime = async ({_listingId}: {_listingId: number}) => {
  const {contract} = await contractMarketplace();
  const listing = await contract.getListing(_listingId);

  console.log('time', listing.endTime.toString());

  return listing.endTime.toNumber();
};

export const wrapMatic = async (value: ethers.BigNumberish) => {
  const {contract} = await contractWrappedMatic();
  const tx = await contract.deposit({value});
  const receipt = await tx.wait();

  return receipt;
};

export const unwrapMatic = async (value: ethers.BigNumberish) => {
  const {contract} = await contractWrappedMatic();
  const tx = await contract.withdraw(value);
  const receipt = await tx.wait();

  return receipt;
};

export const getWmaticBalance = async (address: string) => {
  const {contract} = await contractWrappedMatic();
  const balance = await contract.balanceOf(address);

  return balance;
};

export const getBalance = async (address: string, currAddress: string) => {
  const {contract} = await contractERC20(currAddress);
  const balance = await contract.balanceOf(address);

  return balance;
};

export const getNativeBalance = async (address: string) => {
  const provider = new providers.Web3Provider(window.ethereum as any);
  const balance = await provider.getBalance(address);

  return balance;
};

export const approveWmatic = async (value: ethers.BigNumberish) => {
  const {contract} = await contractWrappedMatic();
  const tx = await contract.approve(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS, value);
  const receipt = await tx.wait();

  return receipt;
};

export const approveWallet = async (value: ethers.BigNumberish, address: string) => {
  const {contract} = await contractERC20(address);
  const tx = await contract.approve(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS, value);
  const receipt = await tx.wait();

  return receipt;
};

export const approveWalletToNFTContract = async (value: ethers.BigNumberish, address: string) => {
  const {contract} = await contractERC20(address);
  const tx = await contract.approve(process.env.NEXT_PUBLIC_NFT_CONTRACT_ADDRESS, value);
  const receipt = await tx.wait();

  return receipt;
};

export const walletAllowance = async (
  ownerAddress: string,
  address: string,
): Promise<ethers.BigNumber> => {
  const {contract} = await contractERC20(address);
  const allowance = await contract.allowance(
    ownerAddress,
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS,
  );
  console.log({allowance});

  return allowance as ethers.BigNumber;
};

export const walletAllowanceToNFTContract = async (
  ownerAddress: string,
  address: string,
): Promise<ethers.BigNumber> => {
  const {contract} = await contractERC20(address);
  const allowance = await contract.allowance(
    ownerAddress,
    process.env.NEXT_PUBLIC_NFT_CONTRACT_ADDRESS,
  );

  return allowance as ethers.BigNumber;
};

export const wmaticAllowance = async (ownerAddress: string): Promise<ethers.BigNumber> => {
  const {contract} = await contractWrappedMatic();
  const allowance = await contract.allowance(
    ownerAddress,
    process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS,
  );

  return allowance as ethers.BigNumber;
};

export const makeOffer = async (params: OfferParamsStruct) => {
  const {contract} = await contractOffers();
  const tx = await contract.offer({...params});
  const receipt = await tx.wait();

  return receipt;
};

export const acceptOffer = async ({_offerId}: {_offerId: BigNumber}) => {
  const {contract} = await contractOffers();
  const tx = await contract.acceptOffer(_offerId);
  const receipt = await tx.wait();
  return receipt;
};

export const placeBid = async ({
  _listingId,
  _quantityWanted,
  _currency,
  _pricePerToken,
  value,
}: {
  _listingId: BigNumber;
  _quantityWanted: BigNumber;
  _currency: string;
  _pricePerToken: BigNumber;
  value: BigNumber;
}) => {
  const {contract} = await contractMarketplace();
  const tx = await contract.bid(_listingId, _quantityWanted, _currency, _pricePerToken, {value});
  const receipt = await tx.wait();
  return receipt;
};
