import axios, {AxiosRequestConfig} from 'axios';

export const axiosAuthInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: {
    encode: (param: any) => {
      try {
        if (param) {
          const rgx = /\[[0-9]*\]/gi;
          return String(param).replace(rgx, '[]');
        }
        return '';
      } catch (err) {
        console.log(err);
      }
    },
  },
  validateStatus: (status) => status >= 200 && status < 300,
});

axiosAuthInstance.interceptors.request.use(function (config: AxiosRequestConfig<unknown>) {
  const token = localStorage.getItem('JWT');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosAuthInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('JWT');
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);
