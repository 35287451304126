import {AxiosResponse} from 'axios';
import axios from './axiosInstance';
import {axiosAuthInstance} from './axiosInstanceAuth';
import cms from './axiosInstanceCms';
import {APIResponse} from './baseResponse';
import {CollectionListInterface} from './collection';
import {ItemInterface} from './item';
import {UserInterface} from './user';

export interface SearchResponse {
  accounts: UserInterface[];
  collections: CollectionListInterface[];
  items: ItemInterface[];
}

export interface NewsProps {
  page: number;
  pageSize: number;
  sort: string;
  isRecommended?: boolean;
  slug?: string;
}

export interface NewsInterface {
  id: number;
  attributes: AttributesNewsInterface;
}

export interface AttributesNewsInterface {
  title: string;
  slug: string;
  content: string;
  keywords: string;
  preventIndexing: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  locale: string;
  summary: string;
  externalSource: string;
  externalUrl: string;
  externalPublicationDate: Date;
  type: string;
  image: AttributesImageInterface;
  Seo: {
    id: number;
    metaTitle: string;
    metaDescription: string;
  };
  localizations: {
    data: [];
  };
}

export interface AttributesImageInterface {
  data: {
    id: number;
    attributes: {
      name: string;
      alternativeText: string;
      caption: string;
      width: number;
      height: number;
      formats: {
        thumbnail: {
          ext: string;
          url: string;
          hash: string;
          mime: string;
          name: string;
          path: string;
          size: number;
          width: number;
          height: number;
        };
      };
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl: string;
      provider: string;
      provider_metadata: string;
      createdAt: Date;
      updatedAt: Date;
    };
  };
}

export interface NewsResponseApi {
  data: Array<NewsInterface>;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface CurrencyInterface {
  id: number;
  address: string;
  chainId: string;
  enabled: boolean;
  name: string;
  symbol: string;
  decimals: number;
  logoUrl: string;
  tokenType: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface ResponseCurrencyInterface {
  status: number;
  message: string;
  metadata: {
    page: string;
    perPage: number;
    pageCount: number;
    totalCount: number;
  };
  records: CurrencyInterface[];
}

export const searchData = async ({name}: {name: string}): Promise<SearchResponse> => {
  return axios
    .get(`search/${name}`)
    .then((res: AxiosResponse<SearchResponse>) => res.data)
    .catch((error) => error);
};

export const getArticles = async ({
  page,
  pageSize,
  sort,
  isRecommended,
  slug,
}: NewsProps): Promise<NewsResponseApi> => {
  return cms
    .get(`articles`, {
      params: {
        populate: '*',
        'pagination[page]': page,
        'pagination[pageSize]': pageSize,
        'sort[publishedAt]': sort,
        'filters[isRecommended]': isRecommended,
        'filters[slug][$ne]': slug,
      },
    })
    .then((res: AxiosResponse<NewsResponseApi>) => res.data)
    .catch((error) => error);
};

export const getArticle = async ({slug}: {slug: string | string[]}): Promise<NewsResponseApi> => {
  return cms
    .get(`articles`, {
      params: {
        populate: '*',
        'filters[slug]': slug,
      },
    })
    .then((res: AxiosResponse<NewsResponseApi>) => res.data)
    .catch((error) => error);
};

export const getCurrencies = async ({
  chainId,
  address,
  name,
  symbol,
  page,
  limit,
  sortBy,
}: {
  chainId?: string;
  address?: string;
  name?: string;
  symbol?: string;
  page?: number;
  limit?: number;
  sortBy?: 'NAME';
}): Promise<ResponseCurrencyInterface> => {
  return axiosAuthInstance
    .get(`marketplace/currency`, {
      params: {
        chainId: chainId,
        address: address,
        name: name,
        symbol: symbol,
        page: page ?? 1,
        limit: limit,
        sortBy: sortBy,
      },
    })
    .then((res: AxiosResponse<APIResponse<ResponseCurrencyInterface>>) => res.data)
    .catch((error) => error);
};
