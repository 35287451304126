const MaticSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1119_3569)">
        <path
          d="M10.6842 4.53155C10.4263 4.38419 10.0947 4.38419 9.8 4.53155L7.73684 5.74734L6.33684 6.52103L4.31053 7.73682C4.05263 7.88419 3.72105 7.88419 3.42632 7.73682L1.84211 6.77892C1.58421 6.63155 1.4 6.33682 1.4 6.00524V4.16313C1.4 3.8684 1.54737 3.57366 1.84211 3.38945L3.42632 2.4684C3.68421 2.32103 4.01579 2.32103 4.31053 2.4684L5.89474 3.42629C6.15263 3.57366 6.33684 3.8684 6.33684 4.19997V5.41576L7.73684 4.60524V3.35261C7.73684 3.05787 7.58947 2.76313 7.29474 2.57892L4.34737 0.847343C4.08947 0.699974 3.75789 0.699974 3.46316 0.847343L0.442105 2.61576C0.147368 2.76313 0 3.05787 0 3.35261V6.81576C0 7.1105 0.147368 7.40524 0.442105 7.58945L3.42632 9.32103C3.68421 9.4684 4.01579 9.4684 4.31053 9.32103L6.33684 8.14208L7.73684 7.33155L9.76316 6.15261C10.0211 6.00524 10.3526 6.00524 10.6474 6.15261L12.2316 7.07366C12.4895 7.22103 12.6737 7.51576 12.6737 7.84734V9.68945C12.6737 9.98419 12.5263 10.2789 12.2316 10.4631L10.6842 11.3842C10.4263 11.5316 10.0947 11.5316 9.8 11.3842L8.21579 10.4631C7.95789 10.3158 7.77368 10.021 7.77368 9.68945V8.5105L6.37368 9.32103V10.5368C6.37368 10.8316 6.52105 11.1263 6.81579 11.3105L9.8 13.0421C10.0579 13.1894 10.3895 13.1894 10.6842 13.0421L13.6684 11.3105C13.9263 11.1631 14.1105 10.8684 14.1105 10.5368V7.03682C14.1105 6.74208 13.9632 6.44734 13.6684 6.26313L10.6842 4.53155Z"
          fill="#9AA09D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1119_3569">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MaticSvg;
