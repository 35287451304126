import {axiosAuthInstance} from './axiosInstanceAuth';
import {AxiosResponse} from 'axios';
import {APIResponse} from './baseResponse';

export enum NotifDataLimit {
  TAKE_3 = 'Take_3',
  TAKE_10 = 'Take_10',
}

export interface CheckNewNotification {
  newNotification: boolean;
}

export const notificationApi = async ({
  userId,
  page,
  take,
}: {
  userId: number;
  page: number;
  take: NotifDataLimit;
}): Promise<any> => {
  return axiosAuthInstance
    .get(`notification/${userId}`, {
      params: {
        page: page,
        take: take,
      },
    })
    .then((response: AxiosResponse<APIResponse<any>>) => response.data)
    .catch((error) => error);
};

export const checkNewNotification = async ({
  userId,
}: {
  userId: number;
}): Promise<CheckNewNotification> => {
  return axiosAuthInstance
    .get(`notification/check-new-notification/${userId}`)
    .then((response: AxiosResponse<APIResponse<CheckNewNotification>>) => response.data)
    .catch((error) => error);
};
