import {ItemActiveListing, Item as ItemClass, ListingType} from '@api/item';
import {UserInterface} from '@api/user';
import MaticSvg from '@components/svg/matic';
import {scientificToDecimal} from '@helpers/number-format';
import resolveImage from '@helpers/resolve-image';
import {WETH_ABI, wmaticAllowance, approveWmatic} from '@helpers/web3';
import {CircularProgress} from '@mui/material';
import Tippy from '@tippyjs/react';
import {ethers} from 'ethers';
import {useForceCorrectNetwork} from 'hooks/force-correct-network';
import React, {useEffect, useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useContractRead} from 'wagmi';
import WrapMaticForm from './wrapMaticForm';

const getPriceNumber = (item: ItemActiveListing) => {
  if (item?.listingType === ListingType.Auction) {
    return item?.bids.length > 0
      ? ethers.utils.formatEther(item?.bids?.[0]?.totalPrice)
      : ethers.utils.formatEther(item?.reservePricePerToken);
  } else if (item?.listingType === ListingType.Direct) {
    return ethers.utils.formatEther(item.buyoutPricePerToken.toString());
  }
};

const MakeBidModal = ({
  show,
  item,
  onClose,
  user,
  onlyMaticWrap = false,
  listingId,
}: {
  show: boolean;
  item?: ItemClass;
  onClose: () => void;
  user?: UserInterface;
  onlyMaticWrap?: boolean;
  listingId?: number;
}) => {
  const [amount, setAmount] = useState<string>('');
  const [minAmount, setMinAmount] = useState<number>(0);
  const [durationDays, setDurationDays] = useState<string>('');
  const [durationHours, setDurationHours] = useState<string>('');
  const [durationMinutes, setDurationMinutes] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledBid, setDisabledBid] = useState<boolean>(true);
  const [disabledWrap, setDisabledWrap] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isWrapMaticFormShown, setIsWrapMaticFormShown] = useState<boolean>(onlyMaticWrap);
  const [wmaticBalance, setWmaticBalance] = useState('');
  const wmaticBalanceRead = useContractRead({
    address: process.env.NEXT_PUBLIC_WRAPPED_MATIC_CONTRACT_ADDRESS as `0x${string}`,
    abi: WETH_ABI,
    watch: true,
    functionName: 'balanceOf',
    args: [user?.wallet_address],
  });
  const [listing, setListing] = useState<ItemActiveListing | null>(null);
  const [amountLarge, setAmountLarge] = useState<boolean>(false);
  const minPrice = getPriceNumber(listing as ItemActiveListing);
  const forceCorrectNetwork = useForceCorrectNetwork();

  useEffect(() => {
    let listing;
    if (item?.is_minted && (item?.supply as number) > 1) {
      listing = item?.listings?.find((l: any) => l.listingId == listingId);
    } else {
      listing = item?.listings?.[0];
    }
    setListing(listing);
  }, [item, listingId]);

  useEffect(() => {
    if (wmaticBalanceRead.isSuccess) {
      setWmaticBalance(ethers.utils.formatEther(wmaticBalanceRead.data?.toString() as string));
    }
  }, [wmaticBalanceRead]);

  const handleAmount = (e: any) => {
    e.preventDefault();
    setAmount(e.target.value);
  };

  const handleDuration = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (type === 'Days') setDurationDays(e.target.value);
      else if (type === 'Hours') setDurationHours(e.target.value);
      else if (type === 'Minutes') setDurationMinutes(e.target.value);
    }
  };

  useEffect(() => {
    if (item) {
      if (listing?.highestOffer?.price !== 0) {
        const minAmount = scientificToDecimal(listing?.highestOffer?.price);
        setMinAmount(minAmount);
      } else {
        const minAmount = scientificToDecimal(listing?.reservePricePerToken);
        setMinAmount(minAmount);
      }
    }
  }, [item]);

  useEffect(() => {
    if (
      amount === '' ||
      !checked ||
      Number(amount.replace(',', '.')) <= minAmount ||
      loading ||
      amountLarge
    )
      setDisabledBid(true);
    else setDisabledBid(false);
  }, [amount, loading, checked, amountLarge]);

  useEffect(() => {
    if (amount !== '') {
      const priceAmount = Number(amount.replace(',', '.'));
      const minAmount = Number(minPrice?.replace(',', '.'));
      setAmountLarge(priceAmount >= minAmount);
    }
  }, [amount]);

  const createBid = async () => {
    try {
      await forceCorrectNetwork();
      setLoading(true);

      const days = 60 * 60 * 24 * Number(durationDays ?? 0);
      const hours = 60 * 60 * Number(durationHours ?? 0);
      const minutes = 60 * Number(durationMinutes ?? 0);
      const expirationTimestamp = Math.floor(Date.now() / 1000) + days + hours + minutes;

      const pricePerToken = ethers.utils.parseEther(amount.replace(',', '.'));
      const totalOfferAmount = pricePerToken.mul(listing?.quantity as number);

      const allowance = await wmaticAllowance(user?.wallet_address as string);
      console.log({allowance});
      if (!allowance.eq(totalOfferAmount)) {
        await approveWmatic(totalOfferAmount);
      }

      // await makeBid({
      //   listingId: listing?.listingId as number,
      //   quantityWanted: listing?.quantity as number,
      //   currency: process.env.NEXT_PUBLIC_WRAPPED_MATIC_CONTRACT_ADDRESS as string,
      //   pricePerToken: ethers.utils.parseEther(amount.replace(',', '.')).toString(),
      //   expirationTimestamp,
      //   listingType: ListingType.Direct,
      // });

      setTimeout(() => {
        setIsSuccess(true);
        setLoading(false);
      }, 3000);
    } catch (error) {
      setIsError(true);
      setLoading(false);
      console.error(error);
      console.log('error');
    }
  };
  return (
    <div>
      <div className={show ? 'modal fade show block' : 'modal fade'}>
        <div className="modal-dialog max-w-xl w-full absolute top-0 pb-10">
          <div className="modal-content !bg-none">
            <div className="modal-header !border-0">
              <h5 className="modal-title" id="placeBidLabel">
                {onlyMaticWrap ? 'Wrap Matic' : 'Make an Offer'}
              </h5>
              <button
                type="button"
                className={`btn-close ${loading && 'hidden'}`}
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-dark-navy h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {!isError && !isSuccess ? (
                isWrapMaticFormShown ? (
                  // Wrap Matic Form
                  <>
                    <WrapMaticForm show={isWrapMaticFormShown} user={user} />
                  </>
                ) : (
                  // Make Offer Form
                  <>
                    <div className="mb-2 flex items-center justify-between">
                      <span className="font-display text-dark-navy text-sm font-semibold dark:text-white">
                        Price
                      </span>
                    </div>

                    <div className="dark:border-dark-grey border-jacarta-100 relative flex items-center overflow-hidden rounded-lg border mb-1">
                      <div className="border-jacarta-100 bg-jacarta-50 dark:bg-black-green-2 flex items-center self-stretch border-r dark:border-r-dark-grey border-r-light-grey px-4 gap-x-4">
                        <span>
                          <MaticSvg />
                        </span>
                        <span className="font-medium text-dark-grey text-[16px]">WMATIC</span>
                      </div>

                      <input
                        type="number"
                        className="focus:ring-green dark:bg-black-green-2 h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70]"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e) => handleAmount(e)}
                        disabled={loading}
                      />
                    </div>
                    <div className="text-sm text-grey">WMATIC balance {wmaticBalance}</div>
                    {amountLarge && (
                      <div className="text-sm text-ruby-red">Must be less than listing price</div>
                    )}

                    <div className="mt-6">
                      <div className="mb-4 flex items-center justify-between">
                        <span className="font-display text-dark-navy text-sm font-semibold dark:text-white">
                          Duration
                        </span>
                      </div>

                      <div className="dark:border-dark-grey border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                        <input
                          type="text"
                          className="focus:ring-green dark:bg-black-green-2 h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70] placeholder:text-dark-grey dark:text-white border-r 
                          dark:border-r-dark-grey border-r-light-grey "
                          placeholder="Days"
                          value={durationDays}
                          onChange={(e) => handleDuration(e, 'Days')}
                          disabled={loading}
                        />
                        <input
                          type="text"
                          className="focus:ring-green dark:bg-black-green-2 h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70] dark:placeholder:text-dark-grey border-r 
                          dark:border-r-dark-grey border-r-light-grey border-jacarta-200"
                          placeholder="Hours"
                          value={durationHours}
                          onChange={(e) => handleDuration(e, 'Hours')}
                          disabled={loading}
                        />
                        <input
                          type="text"
                          className="focus:ring-green dark:bg-black-green-2 h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70] dark:placeholder:text-dark-grey"
                          placeholder="Minutes"
                          value={durationMinutes}
                          onChange={(e) => handleDuration(e, 'Minutes')}
                          disabled={loading}
                        />
                      </div>
                    </div>

                    {/* <!-- Terms --> */}
                    <div className="mt-8 flex items-center space-x-2">
                      <input
                        disabled={loading}
                        type="checkbox"
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                        className="dark:checked:bg-green-2 dark:bg-black-green text-green-2 border-lihgt-grey focus:ring-green-2 dark:border-grey h-5 w-5 self-start rounded focus:ring-offset-0"
                      />
                      <label htmlFor="terms" className="dark:text-jacarta-200 text-sm">
                        By checking this box, I agree to {"Nusa's"}{' '}
                        <a href="#" className="text-green-2">
                          Terms of Service
                        </a>
                      </label>
                    </div>
                  </>
                )
              ) : (
                <Notification item={item} isSuccess={isSuccess} isError={isError} />
              )}
            </div>

            {/* <!-- end body --> */}
            {!onlyMaticWrap && (
              <div
                className={`modal-footer dark:!border-black-green !border-light-grey ${
                  isSuccess || isError ? 'hidden' : 'block'
                }`}
              >
                <div className="flex items-center justify-center space-x-4">
                  <button
                    disabled={disabledBid}
                    onClick={createBid}
                    className={` rounded-full py-3 px-8 text-center font-semibold  transition-all ${
                      disabledBid
                        ? 'bg-black-green text-grey'
                        : 'bg-green-gradation-light text-white'
                    }`}
                  >
                    {loading ? (
                      <div className="flex items-center">
                        <CircularProgress className="!text-grey !h-4 !w-4 mr-2" />
                        <span>Creating Offer...</span>
                      </div>
                    ) : (
                      'Make Offer'
                    )}
                  </button>

                  <button
                    disabled={disabledWrap}
                    onClick={() => setIsWrapMaticFormShown(!isWrapMaticFormShown)}
                    className={` rounded-full py-3 px-8 text-center font-semibold  transition-all ${
                      disabledWrap
                        ? 'bg-black-green text-grey'
                        : 'bg-green-gradation-light text-white'
                    }`}
                  >
                    {isWrapMaticFormShown ? 'Back' : 'Wrap Matic'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Notification = ({
  item,
  isSuccess,
  isError,
}: {
  item: ItemClass | undefined;
  isSuccess: boolean;
  isError: boolean;
}) => {
  const [url, setUrl] = useState<string>();
  const [copied, setCopied] = useState<boolean>(false);

  const handleShare = (type: string) => {
    if (type === 'facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
    } else if (type === 'twitter') {
      window.open(`https://twitter.com/intent/tweet?text=${url}`);
    } else if (type === 'telegram') {
      window.open(`https://t.me/share/url?url=${url}`);
    }
  };

  useEffect(() => {
    if (copied)
      setTimeout(() => {
        setCopied(false);
      }, 3000);
  }, [copied]);

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <div className="w-full ">
      <div className="w-[218px] h-[218px] mb-9 mx-auto">
        <div
          className="w-full pb-[100%] bg-center bg-no-repeat bg-cover rounded-3xl mx-auto"
          style={{
            backgroundImage: `url('${resolveImage(
              item?.image as string,
              '/images/banner-default.png',
            )}')`,
          }}
        ></div>
      </div>
      <div className="text-base text-center mb-9">
        {isSuccess
          ? 'You have successfully bid this item'
          : isError
          ? 'You have failed to bid this item'
          : ''}
      </div>
      <div className={`flex items-center gap-x-6 justify-center ${isError && 'hidden'}`}>
        <div className="text-[10px] text-grey dark:text-light-grey">SHARE</div>
        <Tippy
          hideOnClick={false}
          content={copied ? <span>copied</span> : <span>copy</span>}
          className="p-2"
        >
          <button className="js-copy-clipboard dark:text-jacarta-200 max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap">
            <CopyToClipboard text={url ?? ''} onCopy={() => setCopied(true)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2869 8.71333C10.6043 8.03103 9.67871 7.64774 8.71358 7.64774C7.74846 7.64774 6.82284 8.03103 6.14025 8.71333L3.56608 11.2867C2.88348 11.9693 2.5 12.8951 2.5 13.8604C2.5 14.8258 2.88348 15.7516 3.56608 16.4342C4.24868 17.1168 5.17449 17.5002 6.13983 17.5002C7.10518 17.5002 8.03098 17.1168 8.71358 16.4342L10.0002 15.1475"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-black dark:stroke-[#DEE6E2]"
                />
                <path
                  d="M8.71289 11.2867C9.39548 11.969 10.3211 12.3523 11.2862 12.3523C12.2513 12.3523 13.177 11.969 13.8596 11.2867L16.4337 8.71333C17.1163 8.03073 17.4998 7.10493 17.4998 6.13958C17.4998 5.17424 17.1163 4.24843 16.4337 3.56583C15.7511 2.88323 14.8253 2.49975 13.86 2.49975C12.8946 2.49975 11.9688 2.88323 11.2862 3.56583L9.99956 4.8525"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-black dark:stroke-[#DEE6E2]"
                />
              </svg>
            </CopyToClipboard>
          </button>
        </Tippy>
        <button onClick={() => handleShare('facebook')}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-light-grey"
          >
            <path d="M7.48186 20V10.6154H5V7.23652H7.48186V4.3505C7.48186 2.08264 8.9479 0 12.326 0C13.6937 0 14.7051 0.1311 14.7051 0.1311L14.6254 3.28642C14.6254 3.28642 13.594 3.27638 12.4684 3.27638C11.2502 3.27638 11.055 3.83768 11.055 4.7693V7.23652H14.7222L14.5627 10.6154H11.055V20H7.48186Z" />
          </svg>
        </button>
        <button onClick={() => handleShare('twitter')}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-light-grey"
          >
            <path d="M19.7029 4.11417C19.0071 4.4225 18.2596 4.63083 17.4737 4.725C18.2846 4.23983 18.8912 3.47622 19.1804 2.57667C18.4186 3.02916 17.5849 3.34766 16.7154 3.51833C16.1307 2.89405 15.3563 2.48027 14.5124 2.34123C13.6684 2.20218 12.8022 2.34566 12.0481 2.74938C11.2941 3.1531 10.6944 3.79447 10.3422 4.57392C9.99003 5.35337 9.90503 6.22729 10.1004 7.06C8.55682 6.9825 7.04677 6.58129 5.66827 5.88242C4.28977 5.18355 3.07362 4.20263 2.09875 3.00333C1.76542 3.57833 1.57375 4.245 1.57375 4.955C1.57338 5.59416 1.73078 6.22354 2.03198 6.78728C2.33319 7.35102 2.76888 7.8317 3.30042 8.18667C2.68398 8.16705 2.08114 8.00049 1.54208 7.70083V7.75083C1.54202 8.64728 1.85211 9.51615 2.41974 10.21C2.98736 10.9038 3.77756 11.3799 4.65625 11.5575C4.0844 11.7123 3.48486 11.7351 2.90292 11.6242C3.15083 12.3955 3.63375 13.07 4.28406 13.5533C4.93437 14.0365 5.71951 14.3043 6.52958 14.3192C5.15444 15.3987 3.45616 15.9842 1.70792 15.9817C1.39823 15.9818 1.08881 15.9637 0.78125 15.9275C2.55581 17.0685 4.62153 17.674 6.73125 17.6717C13.8729 17.6717 17.7771 11.7567 17.7771 6.62667C17.7771 6.46 17.7729 6.29167 17.7654 6.125C18.5248 5.57581 19.1803 4.89575 19.7013 4.11667L19.7029 4.11417Z" />
          </svg>
        </button>
        <button onClick={() => handleShare('telegram')}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-light-grey"
          >
            <path d="M19.9412 3.11411L16.9234 17.3463C16.6957 18.3507 16.1019 18.6007 15.2582 18.1275L10.66 14.7391L8.44124 16.873C8.1957 17.1186 7.99034 17.3239 7.51713 17.3239L7.84749 12.6409L16.3698 4.94001C16.7403 4.60965 16.2894 4.42661 15.7939 4.75697L5.2582 11.3909L0.722485 9.97126C-0.264122 9.66322 -0.281979 8.98465 0.927842 8.51144L18.6689 1.67661C19.4903 1.36858 20.2091 1.85965 19.9412 3.11411Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MakeBidModal;
