import {UserInterface} from '@api/user';
import {useApp} from '@components/providers/app-provider';
import ExchangeVertical from '@components/svg/exchange-vertical';
import MaticSvg from '@components/svg/matic';
import {getWmaticBalance, wrapMatic, unwrapMatic, getNativeBalance} from '@helpers/web3';
import {CircularProgress} from '@mui/material';
import {ethers} from 'ethers';
import {useState, useEffect} from 'react';
import {useForceCorrectNetwork} from 'hooks/force-correct-network';

const WrapMaticForm = ({show, user}: {show: boolean; user?: UserInterface}) => {
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [wmaticBalance, setWmaticBalance] = useState<string>('');
  const [nativeBalance, setNativeBalance] = useState<string>('');
  const [direction, setDirection] = useState<'wrap' | 'unwrap'>('wrap');
  const [animate, setAnimate] = useState<boolean>(false);
  const appState = useApp();
  const forceCorrectNetwork = useForceCorrectNetwork();
  const [errorFetchBalance, setErrorFetchBalance] = useState<boolean>(false);

  const handleAmount = (e: any) => {
    e.preventDefault();
    setAmount(e.target.value);
  };

  const changeDirection = () => {
    setAnimate(true);
    if (direction == 'wrap') {
      setDirection('unwrap');
    } else {
      setDirection('wrap');
    }
  };

  useEffect(() => {
    async function fetchBalances() {
      if (user?.wallet_address) {
        try {
          await forceCorrectNetwork();
          await fetchWmaticBalance(user);
          await fetchNativeBalance(user);
          setErrorFetchBalance(false);
        } catch (err) {
          console.log('error fetch balance', {err});
          setErrorFetchBalance(true);
        }
      }
    }
    fetchBalances();
  }, [user?.wallet_address, loading, errorFetchBalance, appState.isModalOpen]);

  const fetchWmaticBalance = async (user: UserInterface) => {
    const bal = await getWmaticBalance(user.wallet_address);
    setWmaticBalance(ethers.utils.formatEther(bal));
  };

  const fetchNativeBalance = async (user: UserInterface) => {
    const bal = await getNativeBalance(user.wallet_address);
    setNativeBalance(ethers.utils.formatEther(bal));
  };

  const process = async () => {
    try {
      await forceCorrectNetwork();
      setLoading(true);
      if (direction == 'wrap') {
        const receipt = await wrapMatic(ethers.utils.parseEther(amount));
      }
      if (direction == 'unwrap') {
        const receipt = await unwrapMatic(ethers.utils.parseEther(amount));
      }
      setLoading(false);
      setAmount('');
    } catch (err) {
      console.log({err});
      setLoading(false);
    }
  };

  const elements = [
    <div key={0}>
      <div
        key={0}
        className="dark:border-dark-grey border-jacarta-100 relative flex items-center overflow-hidden rounded-lg border mb-1"
      >
        <div className="border-jacarta-100 bg-jacarta-50 dark:bg-black-green-2 flex items-center self-stretch border-r dark:border-dark-grey px-4 gap-x-4">
          <span>
            <MaticSvg />
          </span>
          <span className="font-medium text-dark-grey text-[16px]">MATIC</span>
        </div>

        <input
          type="number"
          className="focus:ring-green h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70] dark:bg-black-green-2 "
          placeholder="Amount"
          value={amount}
          onChange={(e) => handleAmount(e)}
          disabled={loading}
          readOnly={direction == 'unwrap'}
        />
      </div>
      <div className="text-sm text-grey">MATIC balance {nativeBalance ?? 0}</div>
    </div>,
    <div key={1} className="flex justify-center my-3">
      <button
        className={`${
          animate ? 'animate-[spin_0.5s]' : ''
        } rounded-full p-3 text-center font-semibold  transition-all bg-green-gradation-light text-white`}
        onClick={() => {
          setAnimate(true);
          changeDirection();
        }}
        onAnimationEnd={() => setAnimate(false)}
      >
        <ExchangeVertical fill="#ffffff" height={30} width={30} />
      </button>
    </div>,
    <div key={2}>
      <div className="dark:border-dark-grey border-jacarta-100 relative flex items-center overflow-hidden rounded-lg border mb-1">
        <div className="border-jacarta-100 bg-jacarta-50 dark:bg-black-green-2 flex items-center self-stretch border-r dark:border-dark-grey px-4 gap-x-4">
          <span>
            <MaticSvg />
          </span>
          <span className="font-medium text-dark-grey text-[16px]">WMATIC</span>
        </div>

        <input
          type="number"
          className="focus:ring-green dark:bg-black-green-2 h-12 w-full flex-[3] border-0 focus:ring-inse dark:placeholder:text-grey/[.70]"
          placeholder="Amount"
          value={amount}
          onChange={(e) => handleAmount(e)}
          disabled={loading}
          readOnly={direction == 'wrap'}
        />
      </div>
      <div className="text-sm text-grey">WMATIC balance {wmaticBalance ?? 0}</div>
    </div>,
  ];

  return (
    <>
      {direction == 'wrap' ? elements : elements.reverse()}
      <div className="flex justify-center mt-6">
        <button
          disabled={loading || !amount}
          onClick={process}
          className={` rounded-full py-3 px-8 text-center font-semibold  transition-all ${
            loading ? 'bg-black-green text-grey' : 'bg-green-gradation-light text-white'
          }`}
        >
          {loading ? (
            <div className="flex items-center">
              <CircularProgress className="!text-grey !h-4 !w-4 mr-2" />
              <span>{`${direction}ping`}...</span>
            </div>
          ) : direction == 'wrap' ? (
            'Wrap'
          ) : (
            'Unwrap'
          )}
        </button>
      </div>
    </>
  );
};

export default WrapMaticForm;
