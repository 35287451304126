import {
  EnglishCommon,
  EnglishCollection,
  EnglishHeader,
  EnglishHome,
  EnglishInfoUser,
  EnglishEditProfile,
  EnglishWatchlist,
  EnglishNews,
  EnglishVoucher,
  EnglishReport,
  EnglishFooter,
} from '../i18n/locales/en';
import {
  BahasaCommon,
  BahasaCollection,
  BahasaHeader,
  BahasaHome,
  BahasaInfoUser,
  BahasaEditProfile,
  BahasaWatchlist,
  BahasaNews,
  BahasaVoucher,
  BahasaReport,
  BahasaFooter,
} from '../i18n/locales/id';

import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

export type Language = 'en' | 'id';

export const useI18n = () => {
  const lng: Language = 'en';

  const changeLanguage = async (to: Language) => {
    if (i18next.isInitialized) {
      i18next.changeLanguage(to);
    }
  };

  const englishResources = {
    common: EnglishCommon,
    collection: EnglishCollection,
    header: EnglishHeader,
    footer: EnglishFooter,
    home: EnglishHome,
    'info-user': EnglishInfoUser,
    'edit-profile': EnglishEditProfile,
    watchlist: EnglishWatchlist,
    news: EnglishNews,
    voucher: EnglishVoucher,
    report: EnglishReport,
  };

  const bahasaResources = {
    common: BahasaCommon,
    collection: BahasaCollection,
    header: BahasaHeader,
    footer: BahasaFooter,
    home: BahasaHome,
    'info-user': BahasaInfoUser,
    'edit-profile': BahasaEditProfile,
    watchlist: BahasaWatchlist,
    news: BahasaNews,
    voucher: BahasaVoucher,
    report: BahasaReport,
  };

  const resources = {
    en: englishResources,
    id: bahasaResources,
  };

  const config = {
    resources,
    lng,
    ns: [
      'common',
      'collection',
      'header',
      'footer',
      'home',
      'info-user',
      'edit-user',
      'watchlist',
      'news',
      'voucher',
    ],
  };

  const init = () => {
    i18next.use(initReactI18next).init(config);
  };

  return {i18next, init, changeLanguage};
};
