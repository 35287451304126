import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface NotifState {
  isNewNotif: boolean;
}

const initialState: NotifState = {
  isNewNotif: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<boolean>) => {
      state.isNewNotif = action.payload;
    },
  },
});

export const {setNotification} = notificationSlice.actions;
export default notificationSlice.reducer;
