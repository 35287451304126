import {getBackendVersion, getFrontendVersion} from '@api/app';
import {useEffect} from 'react';
import {useInterval} from './use-interval';

const BACKEND_VERSION = 'backend-version';
const FRONTEND_VERSION = 'frontend-version';
// Check every 3 minutes
const CHECK_VERSION_INTERVAL = 1000 * 60 * 3;

/**
 * This hook checks the backend and frontend versions and reloads the page if they have changed.
 * This is useful for when you deploy a new version of the app and you want to force the user to
 * refresh the page to get the latest version.
 * */
export const useHandleAppVersionChange = () => {
  const checkVersion = async () => {
    console.log('Checking version');

    try {
      const beVersionLatest = await getBackendVersion();
      const beVersionCurrent = localStorage.getItem(BACKEND_VERSION);

      const feVersionLatest = await getFrontendVersion();
      const feVersionCurrent = localStorage.getItem(FRONTEND_VERSION);

      const beVersionChanged = beVersionCurrent !== beVersionLatest;
      const feVersionChanged = feVersionCurrent !== feVersionLatest;

      if (beVersionChanged || feVersionChanged) {
        localStorage.removeItem('JWT');
        // Update the version in localStorage
        localStorage.setItem(BACKEND_VERSION, beVersionLatest);
        localStorage.setItem(FRONTEND_VERSION, feVersionLatest);
        // refresh the page
        window.location.reload();
      }
    } catch (err) {
      console.log('Error checking version', err);
    }
  };

  // Check the version every 3 minutes
  useInterval(checkVersion, CHECK_VERSION_INTERVAL);

  // Check the version on mount
  useEffect(() => {
    checkVersion();
  }, []);
};
